import { formuleVariablesKeys } from "./calcoloPerUsoProprio";

export function checkHasFormula(formulaType, report) {
  const formulaKey = formuleVariablesKeys[formulaType];
  if (!report.reportInfo || !report.reportInfo.formule) {
    return false;
  }
  const formule = report.reportInfo.formule;
  for (let index = 0; index < formule.length; index++) {
    const formulaObj = formule[index];
    if (typeof formulaObj[formulaKey] === "string") {
      return true;
    }
  }
  return false;
}

export function haQuadro(quadro, installationType, report) {
  if (quadro === "J") {
    if (
      installationType === "CESSIONE_TOTALE" ||
      installationType === "CESSIONE_PARZIALE_COMMERCIALE" ||
      installationType === "CESSIONE_PARZIALE_NON_COMMERCIALE"
    ) {
      return false;
    }
    if (
      installationType === "USO_PROPRIO" &&
      !checkHasFormula("CONSUMI_NON_SOTTOPOSTI", report)
    ) {
      return false;
    }
  }
  if (quadro === "L") {
    if (
      installationType === "CESSIONE_TOTALE" ||
      installationType === "CESSIONE_PARZIALE_COMMERCIALE"
    ) {
      return false;
    }
    if (
      installationType === "USO_PROPRIO" &&
      !checkHasFormula("CONSUMI_ESENTI_L5", report) &&
      !checkHasFormula("CONSUMI_ESENTI_L6", report) &&
      !checkHasFormula("CONSUMI_ESENTI_L8", report)
    ) {
      return false;
    }
  }
  if (quadro === "M") {
    if (
      installationType === "CESSIONE_TOTALE" ||
      installationType === "CESSIONE_PARZIALE_NON_COMMERCIALE"
    ) {
      return false;
    }
    if (installationType === "USO_PROPRIO") {
      if (
        !checkHasFormula("CONSUMI_ASSOGGETTATI_M1", report) &&
        !checkHasFormula("CONSUMI_ASSOGGETTATI_M2", report) &&
        !checkHasFormula("CONSUMI_ASSOGGETTATI_M3", report) &&
        !checkHasFormula("CONSUMI_ASSOGGETTATI", report)
      )
        return false;
    }
  }
  if (quadro === "I") {
    if (installationType === "USO_PROPRIO") {
      if (!checkHasFormula("ENERGIA_ELETTRICA_FATTURATA", report)) return false;
    } else {
      return false;
    }
  }
  return true;
}

export function applyOverrideQuadri(
  quadro,
  report,
  resultAggregated,
  valueKey
) {
  const reportInfo = report.reportInfo;
  const overrideValues =
    reportInfo &&
    reportInfo.overrideQuadri &&
    reportInfo.overrideQuadri[quadro];
  if (!overrideValues) {
    return resultAggregated;
  }

  resultAggregated.forEach((obj) => {
    const { mese, rigaQuadro } = obj;
    const overrideValue =
      overrideValues["rigaQuadro" + rigaQuadro] &&
      overrideValues["rigaQuadro" + rigaQuadro]["mese" + mese];
    if (typeof overrideValue === "number") {
      obj[valueKey] = overrideValue;
    }
  });

  return resultAggregated;
}

export function applyOverrideQuadroQ(report, acconti) {
  const quadro = "Q";
  const reportInfo = report.reportInfo;
  const overrideValues =
    reportInfo &&
    reportInfo.overrideQuadri &&
    reportInfo.overrideQuadri[quadro];
  if (!overrideValues) {
    return acconti;
  }

  const newAcconti = acconti.map((value, index) => {
    const mese = index + 1;
    const key = "mese" + mese;
    const overrideValue = overrideValues[key];
    if (typeof overrideValue === "number") {
      return overrideValue;
    }
    return value;
  });

  return newAcconti;
}

export function getQuadroValue(reportInfo, quadro, rigaQuadro, _valueKey) {
  let valueKeys = _valueKey;
  if (!Array.isArray(valueKeys)) {
    valueKeys = [valueKeys];
  }
  const quadroRows =
    reportInfo && reportInfo.quadri && reportInfo.quadri[quadro];

  let value = undefined;

  (quadroRows || []).forEach((row) => {
    if (row.rigaQuadro == rigaQuadro || row.progressivoRigo == rigaQuadro) {
      valueKeys.forEach((valueKey) => {
        const v = row[valueKey];
        if (typeof v === "number") {
          value = (value || 0) + v;
        }
      });
    }
  });

  return value;
}
