import React from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "src/components/old/reactstrapFix/reactstrap";
import AbstractCard from "src/components/old/AbstractCard";
import numeral from "numeral";
import {
  installationsByType,
  labelTipiContatori,
  tipiDiContatoriWithCeduta,
  tipologieDiCessioneSelect,
  tipologieDiConsumoSelect,
  tipologieDiProduzioneSelect,
  tipologieDiRicezioneSelect,
  tipologieFornituraSelect,
} from "common/installations";
import {
  cloneDeep,
  each,
  find,
  get,
  map,
  mapValues,
  size,
  sortBy,
} from "lodash";
import moment from "moment";
import Loki from "react-loki";
import _validateReport from "common/validator/newReport";
import FunctionEditor from "energix-old/src/app/elements/FunctionEditor";
import { analyzeFormule } from "common/formule";
import pasteHelper from "common/pasteHelper";
import fpSet from "lodash/fp/set";
import { getRiepilogoInfo as _getRiepilogoInfo } from "dogane/fileGenerator/getRiepilogoInfo";
import codiceRegexp from "common/grammar/codiceRegexp";
import {
  reportCanUseDicPerAnno,
  reportShouldUseXml,
  reportShouldUseXmlPerAnno,
} from "common/reports";
import { getDateLettureRichieste } from "dogane/fileGenerator/lettureUtils";
import { formuleVariablesKeysPerInfo } from "dogane/fileGenerator/calcoloPerUsoProprio";
import NumberInput from "src/components/old/NumberInput";
import memoizeOne from "memoize-one";
import { haQuadro } from "dogane/fileGenerator/quadriUtils";
import { checkFileXmlIfNeeded } from "./fileUploadUtils";
import ReportCardTitle from "./ReportCardTitle";
import memoizerific from "memoizerific";
import { isGenerazioneXmlEInvioDisabilitatoPerNuovoAnno } from "src/components/common/anniUtils";
import { UsaDatiLetture } from "../UsaDatiLetture";
import {
  DatiFornitoreLetturaCell,
  DatiFornitoreLetturaCellUsaTuttiIMesi,
} from "../DatiFornitoreLetturaCell";
import { Typography } from "@mui/material";
import { ReportStepQuadroEF } from "./ReportStep_QuadroEF";

function _getValueOrDefault(formulaObj, key, defaultValue) {
  const v = formulaObj[key];
  if (v == null) {
    return defaultValue;
  }
  return v;
}

function set(state, path, value) {
  return fpSet(path, value, state);
}

const MESI = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];

const frequenzeLetture = [
  { value: 1, label: "Mensile" },
  { value: 6, label: "Semestrale" },
];

function chiediFrequenzeLetture(installation) {
  return installation.type === "USO_PROPRIO";
}

const tipologieDiConsumoEsente = [
  {
    label: "Quadro L - Consumi esenti - L6 (DEFAULT)",
    value: "L6",
    help: "La differenza tra produzione e cessione viene inserita in L6, al netto di eventuali consumi contabilizzati da contatori C (consumi L5).",
  },
  {
    label: "Quadro L - Consumi esenti - L5",
    value: "L5",
    help: "La differenza tra produzione e cessione viene inserita in L5. Eventuali contatori C vengono riportati solamente nel quadro C.",
  },
  {
    label: "Perdite",
    value: "Perdite",
    help: "La differenza tra produzione e cessione viene inserita come Perdite, senza inserimento di consumi mensili esenti.",
  },
];

const FAKE_SERVICE_TRUE = { sendPdfOnly: true };
const FAKE_SERVICE_FALSE = { sendPdfOnly: false };
const validateReport = memoizerific(50)(_validateReport);

class ReportCardSteps extends AbstractCard {
  static propTypes = {
    ...AbstractCard.propTypes,
    installation: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    report: PropTypes.object,
  };

  static defaultProps = {
    modalClassName: "report-card-modal",
  };

  onSuccessData(payload) {
    return payload;
  }

  getValue(key) {
    const v = super.getValue(key);
    if (key === "reportInfo.meseConguaglio" && !v) {
      return 3;
    }
    if (key === "reportInfo.meseConguaglio2" && !v) {
      return 11;
    }
    if (typeof key === "string" && key.endsWith("inserimentoDirettoKwh")) {
      return v || false;
    }
    return v;
  }

  setValue(key, value) {
    let newData = { ...this.state.data };

    if (/numeroCifre(Intere|Decimali)/.test(key)) {
      value = parseInt(value);
    }

    if (key === "reportInfo.meseConguaglio") {
      value = parseInt(value);
    }

    if (typeof key === "string" && key.endsWith("inserimentoDirettoKwh")) {
      if (value) {
        //reset k numeroCifreIntere numeroCifreDecimali azzerato
        newData = set(newData, key.replace("inserimentoDirettoKwh", "k"), 1);
        newData = set(
          newData,
          key.replace("inserimentoDirettoKwh", "numeroCifreIntere"),
          undefined
        );
        newData = set(
          newData,
          key.replace("inserimentoDirettoKwh", "numeroCifreDecimali"),
          0
        ); //kwh sono sempre interi
        newData = set(
          newData,
          key.replace("inserimentoDirettoKwh", "azzerato"),
          false
        );
      }
    }

    if (/^reportInfo\.contatori\.[0-9]+\.matricola$/.test(key)) {
      const { installation } = this.props;
      const installationType = installationsByType[installation.type];
      const haFormule = installationType && installationType.necessitaAlgoritmo;
      if (!haFormule) {
        //cancella il codice!
        const codicePath = key.replace("matricola", "codice");
        newData = set(newData, codicePath, undefined);
      }
    }

    const isFormulaEndDate = /reportInfo\.formule\.[0-9]+\.endDate/.test(key);
    if (isFormulaEndDate && !value) {
      return;
    }

    newData = set(newData, key, value);

    if (isFormulaEndDate) {
      const oldFormule = get(newData, "reportInfo.formule");
      var done = false;
      var lastEndDate = null;
      const anno = this.getValue("anno");
      const newFormule = oldFormule
        .map((obj) => {
          if (done) {
            return null;
          }
          if (lastEndDate) {
            var startDate = lastEndDate;
            if (obj.endDate <= startDate) {
              return null;
            }
            obj = { ...obj, startDate: startDate };
          }
          lastEndDate = obj.endDate;
          if (lastEndDate === anno - 1 + "-12-31") {
            done = true;
          }
          return obj;
        })
        .filter((x) => x);
      if (!done) {
        newFormule.push({
          startDate: lastEndDate,
          endDate: anno - 1 + "-12-31",
        });
      }
      newData = set(newData, "reportInfo.formule", newFormule);
    }

    this.setState({
      data: newData,
    });
  }

  constructor(props) {
    const { installation, report } = props;
    let initialData;
    if (installation && report) {
      initialData = cloneDeep(report);
    }
    super(props, initialData);
    this.state.contatoriRemoved = [];
  }

  renderButtons() {
    if (this.props.isClienteFinaleWebsite) {
      return null;
    }
    return super.renderButtons();
  }

  submitAction(payload) {
    const { report } = this.props;
    const saveAsBozza = this.saveAsBozza;

    if (this.props.dichiarazione) {
      payload.caricaSoloDichiarazione = true;
    } else {
      // SE GIA INVIATO NON PUOI!
      const filesToCheck2 = ["fileConfermaPDFId", "fileConfermaSignedId"];
      var hasFiles2 = false;
      filesToCheck2.forEach((file) => {
        if (payload[file]) {
          hasFiles2 = true;
        }
      });

      if (hasFiles2) {
        window.alert(
          "Attenzione!\n" +
            "\n" +
            "Questa dichiarazione è già stata inviata e quindi non può essere modificata."
        );
        return Promise.resolve();
      }

      // ELIMINA I FILE DELLA RENDICONTAZIONE...
      // SE C'ERANO, AVVISA PRIMA DI CONTINUARE
      const filesToCheck = [
        "fileDicId",
        "fileDichiarazionePDFId",
        "fileC01Id",
        "fileC01SignedId",
        "fileXmlId",
        "fileXmlSignedId",
        "fileConfermaPDFId",
        "fileConfermaSignedId",
        "fileRiepilogoAcciseXlsId",
      ];

      var hasFiles = false;
      filesToCheck.forEach((file) => {
        if (payload[file]) {
          hasFiles = true;
        }
        payload[file] = null;
      });

      if (hasFiles) {
        const ok = window.confirm(
          "Attenzione!\n" +
            "\n" +
            "Hai già prodotto alcuni file relativi a questa dichiarazione. Se continui, questi file verranno cancellati.\n" +
            "\n" +
            "Sei sicuro di voler continuare?"
        );
        if (!ok) {
          return Promise.resolve();
        }
      }
      if (
        reportShouldUseXml(report) &&
        !isGenerazioneXmlEInvioDisabilitatoPerNuovoAnno(report) &&
        !saveAsBozza
      ) {
        payload.generateXmlFile = true;
      }
    }

    let payloadToSave = {
      ...payload,
      reportInfo: {
        ...payload.reportInfo,
      },
    };
    if (saveAsBozza) {
      payloadToSave.reportInfo.statoReport = "bozza";
    } else {
      delete payloadToSave.reportInfo.statoReport;
    }

    if (report && report.id) {
      return this.props.updateReport(payloadToSave);
    } else {
      return this.props.createNewReport(payloadToSave);
    }
  }

  renderTitle() {
    const anno = this.getValue("anno");
    const { installation } = this.props;
    return <ReportCardTitle anno={anno} impianto={installation} />;
  }

  onLetture = (letture) => {
    console.log(letture);
    this.setState({
      hasDatiLetture: true,
      lettureFornitore: letture,
    });
  };

  createLettureTableRows(contatore, baseKey, showUsaDatiLetture) {
    const { installation, isAdmin, isPartner, isClienteFinaleWebsite } =
      this.props;
    const installationType = installationsByType[installation.type];
    const letturaAnnuale = installationType.letturaAnnuale;
    const report = this.state.data;

    const anno = this.getValue("anno");

    const matricola = this.getValue(baseKey + ".matricola");
    const matricolaEdistribuzione =
      this.state.matricoleEdistribuzione?.[baseKey] || null;
    const tipoContatore = this.getValue(baseKey + ".type");
    const k = this.getValue(baseKey + ".k");
    const numeroCifreIntere = this.getValue(baseKey + ".numeroCifreIntere");
    const numeroCifreDecimali = this.getValue(baseKey + ".numeroCifreDecimali");

    const setMatricolaEdistribuzione = (matr) => {
      const newMatricoleEdistribuzione =
        this.state.matricoleEdistribuzione || {};
      newMatricoleEdistribuzione[baseKey] = matr || null;
      this.setState({ matricoleEdistribuzione: newMatricoleEdistribuzione });
    };

    const inserimentoDirettoKwh = this.getValue(
      baseKey + ".inserimentoDirettoKwh"
    );

    const formuleOrNull = this.getValue("reportInfo.formule") || null;
    const lettureRichieste = getDateLettureRichieste(
      anno - 1,
      contatore.startDate,
      contatore.endDate,
      letturaAnnuale,
      report,
      formuleOrNull
    );

    const lettureTableRows = [
      {
        label: "Matricola",
        columnKey: ".matricola",
      },
      {
        isCostanteLettura: true,
        hideSeInserimentoDirettoKwh: true,
        label: "Costante di lettura",
        columnType: "number",
        decimalDigits: 2,
        columnKey: ".k",
      },
      {
        hideSeInserimentoDirettoKwh: true,
        label: "Numero di cifre intere",
        columnType: "select",
        options: [1, 2, 3, 4, 5, 6, 7, 8].map((v) => ({ value: v, label: v })),
        columnKey: ".numeroCifreIntere",
      },
      {
        hideSeInserimentoDirettoKwh: true,
        label: "Numero di cifre decimali",
        columnType: "select",
        options: [0, 1, 2, 3, 4].map((v) => ({ value: v, label: v })),
        columnKey: ".numeroCifreDecimali",
      },
      {
        hideSeInserimentoDirettoKwh: true,
        label: "Durante l'anno il contatore è ripartito da zero?",
        columnType: "checkbox-radio",
        radioLabels: ["Sì", "No"],
        columnKey: ".azzerato",
      },
      // !isClienteFinaleWebsite && {
      //   label: <div style={{marginTop: 20}}>Stato letture</div>,
      //   columnType: 'select',
      //   options: [
      //     {value: undefined, label: "Definitive"},
      //     {value: "in-seguito", label: "Da inserire"},
      //   ],
      //   showEmptyOption: false,
      //   columnKey: '.statoLetture'
      // },
      {
        label: (
          <div style={{ marginTop: 20, fontWeight: "bold" }}>
            {inserimentoDirettoKwh ? "kWh" : "Letture"}
          </div>
        ),
        colonnaDatiFornitore: this.state.hasDatiLetture ? (
          <>
            <div
              style={{ marginTop: 20, marginBottom: -5, fontWeight: "bold" }}
            >
              {/* Dati fornitore (kWh) */}
              {"Lettura suggerita"}
            </div>
            <Typography variant="caption">
              (In base ai consumi forniti dal distrubutore)
            </Typography>
            <div>
              <DatiFornitoreLetturaCellUsaTuttiIMesi
                anno={anno}
                letture={this.state.lettureFornitore}
                lettureGiaInserite={this.getValue(baseKey + ".letture")}
                matricola={matricola}
                matricolaEdistribuzione={matricolaEdistribuzione}
                setMatricolaEdistribuzione={setMatricolaEdistribuzione}
                tipoContatore={tipoContatore}
                meseKeys={lettureRichieste.map(({ key }) => key)}
                k={k}
                numeroCifreIntere={numeroCifreIntere}
                numeroCifreDecimali={numeroCifreDecimali}
                usaValoriSuggeriti={(valueForKeys) => {
                  const letture = this.getValue(baseKey + ".letture");
                  const newLetture = {
                    ...letture,
                    ...valueForKeys,
                  };
                  this.setValue(baseKey + ".letture", newLetture);
                }}
              />
            </div>
          </>
        ) : null,
        columnKey: null,
      },
    ].filter((x) => x);

    if (showUsaDatiLetture && !this.state.hasDatiLetture) {
      lettureTableRows.push({
        label: (
          <div>
            <UsaDatiLetture
              impianto={this.props.installation}
              anno={anno - 1}
              getLetture={this.props.getLetture}
              getLetturePaid={this.props.getLetturePaid}
              onLetture={this.onLetture}
            />
          </div>
        ),
        colSpan: this.state.hasDatiLetture ? 3 : 2,
        columnKey: null,
      });
    }

    lettureRichieste.forEach(({ label, key }, index) => {
      lettureTableRows.push({
        isFirstLettura: index === 0,
        label: label,
        columnType: "number",
        // decimalDigits: 0,
        columnKey: ".letture." + key,
        isLettura: true,
        colonnaDatiFornitore:
          this.state.hasDatiLetture && this.state.lettureFornitore ? (
            <DatiFornitoreLetturaCell
              anno={anno}
              letture={this.state.lettureFornitore}
              lettureGiaInserite={this.getValue(baseKey + ".letture")}
              matricola={matricolaEdistribuzione || matricola}
              tipoContatore={tipoContatore}
              meseKey={key}
              k={k}
              numeroCifreIntere={numeroCifreIntere}
              numeroCifreDecimali={numeroCifreDecimali}
              usaValoreSuggerito={(v) => {
                this.setValue(baseKey + ".letture." + key, v);
              }}
            />
          ) : null,
      });
    });

    return lettureTableRows;
  }

  getContatoreTitle(index) {
    const contatore = this.getValue("reportInfo.contatori." + index) || {};
    if (contatore.codice) {
      return "Contatore " + contatore.codice;
    }
    if (contatore.matricola) {
      return "Contatore " + contatore.matricola;
    }
    return "Contatore #" + (index + 1);
  }

  getContatoreSubtitle(index) {
    const { installation } = this.props;

    const contatore = this.getValue("reportInfo.contatori." + index) || {};

    if (
      installation.type === "CESSIONE_TOTALE" &&
      !this.getValue("reportInfo.haContatoriDiversi")
    ) {
      return labelTipiContatori.produzione + " + " + labelTipiContatori.ceduta;
    }

    return labelTipiContatori[contatore.type] || "";
  }

  onPasteLetture = (
    contatoreIndex,
    lettureTableRows,
    rowIndex,
    event,
    bidimensional = false
  ) => {
    const { installation } = this.props;
    const installationType = installationsByType[installation.type];
    const letturaAnnuale = installationType.letturaAnnuale;
    const contatori = this.getValue("reportInfo.contatori") || [];

    const baseKey = "reportInfo.contatori." + contatoreIndex;

    pasteHelper(
      event,
      true,
      (values, is2D) => {
        let newData = { ...this.state.data };

        let deltaM = 1;
        if (letturaAnnuale) {
          deltaM = 12;
        }

        for (var m = rowIndex, i = 0; i < values.length; i++, m++) {
          const row = lettureTableRows[m];
          if (!row || !row.isLettura) {
            break;
          }

          if (is2D) {
            values[i].forEach((val, colI) => {
              const contIndex = contatoreIndex + colI;
              if (contIndex < contatori.length) {
                const key = "reportInfo.contatori." + contIndex + row.columnKey;
                newData = set(newData, key, val);
              }
            });
          } else {
            const key = baseKey + row.columnKey;
            let value = values[i];
            newData = set(newData, key, value);
          }
        }

        this.setState({
          data: newData,
        });
      },
      bidimensional
    );
  };

  renderContatore(index, isStepLetture = false) {
    const { isClienteFinaleWebsite } = this.props;

    const baseKey = "reportInfo.contatori." + index;
    const contatore = this.getValue(baseKey);

    const lettureTableRows = this.createLettureTableRows(
      contatore,
      baseKey,
      true //showUsaDatiLetture
    );

    const type = this.getValue(baseKey + ".type");

    const { installation } = this.props;
    const installationType = installationsByType[installation.type];

    const tipologiaDiCessione =
      installationType.tipologiaDiCessione &&
      installationType.tipologiaDiCessione(type, this.getValue("reportInfo"));
    const tipologiaDiRicezione =
      installationType.tipologiaDiRicezione &&
      installationType.tipologiaDiRicezione(type);
    const showIsRinnovabile = type === "produzione";

    let title = this.getContatoreTitle(index);
    let realTitle = title;
    let subtitle = this.getContatoreSubtitle(index);
    if (subtitle) {
      title += " " + subtitle;
    }

    // CREA LE RIGHE DELLA TABELLA
    const lettureRows = [];
    const stepLettureInfo = {
      title: realTitle,
      subtitle: subtitle,
      labels: {},
      rows: {},
    };

    let decimalDigitsForLetture = this.getValue(
      baseKey + ".numeroCifreDecimali"
    );
    if (
      typeof decimalDigitsForLetture === "undefined" ||
      decimalDigitsForLetture === null
    ) {
      decimalDigitsForLetture = 3;
    }

    const inserimentoDirettoKwh = this.getValue(
      baseKey + ".inserimentoDirettoKwh"
    );

    lettureTableRows.forEach((row, rowIndex) => {
      if (row.isCostanteLettura) {
        lettureRows.push(
          <tr key="kWh o letture">
            <td>Inserimento diretto kWh</td>
            <td style={row.columnStyle}>
              {row.columnKey &&
                this.renderPureInput(baseKey + ".inserimentoDirettoKwh", {
                  type: "checkbox-radio",
                  radioLabels: ["Sì", "No"],
                })}
              {inserimentoDirettoKwh && (
                <div>
                  <Alert color="warning">
                    ATTENZIONE
                    <br />
                    Non verranno inserite le letture!
                    <br />
                    I quadri dei contatori verranno compilati senza letture e
                    costanti, ma solo con i kWh.
                    <br />
                    Procedi solo se concordato con l’Ufficio doganale.
                  </Alert>
                </div>
              )}
            </td>
          </tr>
        );
      }
      if (inserimentoDirettoKwh && row.hideSeInserimentoDirettoKwh) {
        return;
      }
      if (inserimentoDirettoKwh && row.isFirstLettura) {
        // la prima letture è sempre la fine del mese prima o cmq l'inizio
        // ma quando differenzaLetture si accorge che stiamo facendo inserimentoDirettoKwh
        // non fa effettivamente la differenza, ma usa la letture finale del mese che è quello
        // che inserirsce l'utente come "kwh"
        // TLDR: non serve la prima lettura!!
        return;
      }

      const bidimensional = isStepLetture;

      const cellContent = (
        <React.Fragment>
          {row.columnKey &&
            this.renderPureInput(baseKey + row.columnKey, {
              type: row.columnType,
              decimalDigits: row.isLettura
                ? /*lettura*/ decimalDigitsForLetture
                : row.decimalDigits,
              showThousandSeparator: row.isLettura ? true : false,
              options: row.options,
              radioLabels: row.radioLabels,
              showEmptyOption: row.showEmptyOption,
              onPaste: (e) =>
                this.onPasteLetture(
                  index,
                  lettureTableRows,
                  rowIndex,
                  e,
                  bidimensional
                ),
            })}
          {row.columnKey && this.renderError(baseKey + row.columnKey)}
        </React.Fragment>
      );

      lettureRows.push(
        <tr key={rowIndex}>
          <td colSpan={row.colSpan || undefined}>{row.label}</td>
          <td style={row.columnStyle}>{cellContent}</td>
          {this.state.hasDatiLetture && row.colonnaDatiFornitore ? (
            <td>{row.colonnaDatiFornitore}</td>
          ) : null}
        </tr>
      );

      if (row.isLettura) {
        stepLettureInfo.labels[row.columnKey] = row.label;
        stepLettureInfo.rows[row.columnKey] = cellContent;
      }
    });

    if (isStepLetture) {
      return stepLettureInfo;
    }

    // ritorna la card del contatore
    return (
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              {title}
              {!isClienteFinaleWebsite && installationType.helpContatore && (
                <div className="float-right">
                  {this.renderHelpPopover(
                    "rendicontazioneContatore-" + installation.type + "-" + type
                  )}
                </div>
              )}
            </CardHeader>
            <CardBody>
              {!isClienteFinaleWebsite && (
                <Row>
                  {showIsRinnovabile &&
                    this.renderInput(
                      "Tipologia di produzione",
                      baseKey + ".tipologiaDiProduzione",
                      {
                        horizontal: true,
                        type: "select",
                        options: tipologieDiProduzioneSelect,
                        showEmptyOption: false,
                      }
                    )}
                  {tipologiaDiCessione &&
                    this.renderInput(
                      "Tipologia di cessione",
                      baseKey + ".tipologiaDiCessione",
                      {
                        horizontal: true,
                        type: "select",
                        options: tipologieDiCessioneSelect,
                      }
                    )}
                  {tipologiaDiCessione &&
                    this.renderInput(
                      "Codice identificativo officina destinataria",
                      baseKey + ".codiceIdentificativoOfficinaDestinataria",
                      { horizontal: true }
                    )}
                  {tipologiaDiRicezione &&
                    this.renderInput(
                      "Tipologia di ricezione",
                      baseKey + ".tipologiaDiRicezione",
                      {
                        horizontal: true,
                        type: "select",
                        options: tipologieDiRicezioneSelect,
                      }
                    )}
                  {tipologiaDiRicezione &&
                    this.renderInput(
                      "Codice identificativo officina fornitrice",
                      baseKey + ".codiceIdentificativoOfficinaFornitrice",
                      { horizontal: true }
                    )}
                  {this.renderInput("Valido da:", baseKey + ".startDate", {
                    horizontal: true,
                    type: "date",
                  })}
                  {this.renderInput("Valido a:", baseKey + ".endDate", {
                    horizontal: true,
                    type: "date",
                  })}
                </Row>
              )}
              <Row style={{ marginTop: 15 }}>
                <Col md="12">
                  <table
                    className={
                      "readings-table" +
                      (this.state.hasDatiLetture
                        ? " readings-table-con-dati-contatori"
                        : "")
                    }
                  >
                    <thead>
                      <tr>
                        <th className="first-column"></th>
                        <th style={{ textAlign: "center" }}></th>
                      </tr>
                      <tr>
                        <th></th>
                        <th style={{ textAlign: "center" }}>Dati contatore</th>
                        {this.state.hasDatiLetture && <th></th>}
                      </tr>
                    </thead>
                    <tbody>{lettureRows}</tbody>
                  </table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  renderStepLetture() {
    const contatori = this.getValue("reportInfo.contatori") || [];
    // const rowsKeys = {};
    // const rowsLabels = {};
    // const columns = [];
    // for (let ci = 0; ci < contatori.length; ci++) {
    //   const info = this.renderContatore(ci, true);
    //   columns.push({
    //     title: info.title,
    //     subtitle: info.subtitle,
    //     rows: info.rows,
    //   });
    //   each(info.labels, (l, key) => {
    //     rowsKeys[key] = true;
    //     rowsLabels[key] = l;
    //   });
    // }

    // const rows = Object.keys(rowsKeys).sort();

    // le label sono quelle che fanno fede...
    // quindi basiamo le righe su quelle, poi per ogni contatore una label può
    // avere key diversa da un'altro, ma fa niente, mostriamo quello corretto

    const rowsLabelsExists = {};
    const columns = [];
    for (let ci = 0; ci < contatori.length; ci++) {
      const info = this.renderContatore(ci, true);

      const rowsLabelsToKey = {};
      each(info.labels, (l, key) => {
        rowsLabelsExists[l] = true;
        rowsLabelsToKey[l] = key;
      });

      columns.push({
        title: info.title,
        subtitle: info.subtitle,
        rows: info.rows,
        rowsLabelsToKey: rowsLabelsToKey,
      });
    }

    const rowsLabelsByDate = {};
    each(rowsLabelsExists, (_, l) => {
      const d = moment(l, "DD MMMM Y").format("YYYY-MM-DD");
      rowsLabelsByDate[d] = l;
    });

    //ordered dates!
    const rows = Object.keys(rowsLabelsByDate)
      .sort()
      .map((d) => {
        return rowsLabelsByDate[d];
      });

    return (
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>Letture</CardHeader>
            <CardBody>
              <Row style={{ marginTop: 15 }}>
                <Col md="12" className="step-letture-readings-table-container">
                  <table className="readings-table step-letture-readings-table">
                    <thead>
                      <tr>
                        <th className="first-column"></th>
                        {columns.map((col, index) => {
                          return (
                            <th key={index}>
                              <div>{col.title}</div>
                              <div>{col.subtitle}</div>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((label) => {
                        return (
                          <tr key={label}>
                            <td>{label}</td>
                            {columns.map((col, index) => {
                              const key = col.rowsLabelsToKey[label];
                              const rowContent = (key && col.rows[key]) || null;
                              return <td key={index}>{rowContent}</td>;
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  onDichiarazioneFile(key, event) {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      this.setState({
        uploadingFile: {
          ...this.state.uploadingFile,
          [key]: true,
        },
      });

      checkFileXmlIfNeeded(file, this.props.installation).then((ok) => {
        if (!ok) {
          this.setState({
            uploadingFile: {
              ...this.state.uploadingFile,
              [key]: false,
            },
          });
          return;
        }

        this.props.uploadFile(file).then((action) => {
          const fileId = action.response && action.response.fileId;
          if (fileId) {
            this.setState({
              uploadingFile: {
                ...this.state.uploadingFile,
                [key]: false,
              },
              data: {
                ...this.state.data,
                [key]: fileId,
              },
            });
          }
        });
      });
    } else {
      this.setValue(key, undefined);
    }
  }

  renderDichiarazione() {
    const anno = this.getValue("anno");
    const usaXml = reportShouldUseXmlPerAnno(anno);
    const canDic = reportCanUseDicPerAnno(anno);

    const fileList = [
      canDic && {
        key: "fileDicId",
        label: "File .DIC",
      },
      {
        key: "fileDichiarazionePDFId",
        label: "File dichiarazione PDF",
      },
      canDic && {
        key: "fileC01Id",
        label: "File .CXX",
      },
      canDic && {
        key: "fileC01SignedId",
        label: "File .CXX firmato (.p7m)",
      },
      usaXml && {
        key: "fileXmlId",
        label: "File .xml",
      },
      usaXml && {
        key: "fileXmlSignedId",
        label: "File .xml firmato",
      },
      {
        key: "fileConfermaPDFId",
        label: "File conferma operazione PDF",
      },
      {
        key: "fileConfermaSignedId",
        label: "File conferma operazione firmato",
      },
      {
        key: "fileRiepilogoAcciseXlsId",
        label: "File riepilogo accise (.xlsx)",
      },
    ].filter((x) => x);
    return (
      <Row>
        {fileList.map((fileInfo, index) => {
          const fileValue = this.getValue(fileInfo.key);
          const fileAlreadyPresent = fileValue && !(fileValue instanceof File);
          const uploading =
            this.state.uploadingFile && this.state.uploadingFile[fileInfo.key];

          return (
            <Col key={index} md="12">
              <FormGroup row>
                <Col xs="5" md="4" lg="4">
                  {fileInfo.label}
                </Col>
                <Col xs="7" md="8" lg="8">
                  {fileAlreadyPresent && (
                    <span>
                      File già caricato.{" "}
                      <a
                        href={"/file/" + fileValue + "/download"}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Scarica
                      </a>
                      <br />
                      <span>
                        <Button
                          color="link"
                          onClick={() => this.setValue(fileInfo.key, null)}
                        >
                          Cancella
                        </Button>
                      </span>
                    </span>
                  )}
                  {!fileAlreadyPresent && (
                    <input
                      name={fileInfo.key}
                      type="file"
                      onChange={(e) =>
                        this.onDichiarazioneFile(fileInfo.key, e)
                      }
                    />
                  )}
                  {uploading && <span>Caricamento in corso...</span>}
                </Col>
              </FormGroup>
            </Col>
          );
        })}
      </Row>
    );
  }

  onPasteAccise = (month, event) => {
    pasteHelper(event, true, (values) => {
      let newData = { ...this.state.data };

      for (var m = month; m <= 12 && m - month < values.length; m++) {
        const key = "reportInfo.accontiAnnoPrecedente." + m;
        let value = values[m - month];
        newData = set(newData, key, value);
      }

      this.setState({
        data: newData,
      });
    });
  };

  renderAccise() {
    const acconti = this.getValue("reportInfo.accontiAnnoPrecedente") || [];
    const total = acconti.reduce((s, v) => s + (v || 0), 0);

    const anno = this.getValue("anno");

    // conguaglioRateale2020
    let askConguaglioRateale2020 = false;
    if (anno - 1 === 2020) {
      askConguaglioRateale2020 = true;
    }

    // creditoPregressoNonUtilizzato
    let askCreditoPregressoNonUtilizzato = false;
    let askCreditoPregressoNonUtilizzato2021 = false;
    if (anno - 1 === 2021) {
      askCreditoPregressoNonUtilizzato2021 = true;
    } else if (anno - 1 > 2021) {
      askCreditoPregressoNonUtilizzato = true;
    }

    const rateoAccontoDivisore =
      this.getValue("reportInfo.rateoAccontoDivisore") || 12;

    const setRateoAccontoDivisore = (v) => {
      this.setValue("reportInfo.rateoAccontoDivisore", v);
    };

    const rateoAccontoOgniNumeroMesi =
      this.getValue("reportInfo.rateoAccontoOgniNumeroMesi") || 1;

    const setRateoAccontoOgniNumeroMesi = (v) => {
      this.setValue("reportInfo.rateoAccontoOgniNumeroMesi", v);
    };

    const meseInizioAttivita =
      this.getValue("reportInfo.meseInizioAttivita") || 0;
    const setMeseInizioAttivita = (v) => {
      this.setValue("reportInfo.meseInizioAttivita", v);
    };

    return (
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>Mese inizio attività</CardHeader>
            <CardBody>
              <div style={{ maxWidth: 300 }}>
                <Input
                  type="select"
                  value={meseInizioAttivita}
                  onChange={(e) =>
                    setMeseInizioAttivita(parseInt(e.target.value, 10))
                  }
                >
                  <option key={0} value={0}>
                    0
                  </option>
                  {MESI.map((valueString, i) => {
                    const value = i + 1;
                    return (
                      <option key={value} value={value}>
                        {value} - {valueString}
                      </option>
                    );
                  })}
                </Input>
              </div>
              <div className="text-muted" style={{ marginTop: 20 }}>
                Se l'attività è iniziata nel corso dell'anno oggetto di questa
                dichiarazione, impostare il mese di inizio attività. Altrimenti
                lasciare il valore 0
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md="12">
          <Card style={{ marginTop: 20 }}>
            <CardHeader>
              Acconti accisa indicati nell’ultima dichiarazione annuale di
              consumi
              <div className="float-right">
                {this.renderHelpPopover("accontiAccisaAnnoPrecedente")}
              </div>
            </CardHeader>
            <CardBody>
              <table>
                <tbody>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
                    const mese = MESI[month - 1];
                    return (
                      <tr key={month}>
                        <td>{mese}:</td>
                        <td style={{ width: 40 }}></td>
                        <td>
                          {this.renderPureInput(
                            "reportInfo.accontiAnnoPrecedente." + month,
                            {
                              horizontal: "2",
                              type: "number",
                              decimalDigits: 2,
                              onPaste: (e) => this.onPasteAccise(month, e),
                            }
                          )}
                          {this.renderError(
                            "reportInfo.accontiAnnoPrecedente." + month
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  <tr style={{ height: 15 }}></tr>
                  <tr>
                    <td>
                      Ratei d’acconto calcolati nella precedente dichiarazione
                    </td>
                    <td></td>
                    <td>{total.toFixed(2).replace(".", ",") + " €"}</td>
                  </tr>
                  {askConguaglioRateale2020 && (
                    <tr>
                      <td>
                        Ratei d’acconto effettivi (per effetto di quanto
                        previsto dall’art. 129, comma 1, D.L. n. 34/2020)
                      </td>
                      <td></td>
                      <td>
                        {acconti
                          .reduce((s, v, i) => {
                            v = v || 0;
                            // 90% da maggio (5) a settembre (9)
                            if (i >= 5 && i <= 9) {
                              v = v * 0.9;
                            }
                            return s + v;
                          }, 0)
                          .toFixed(2)
                          .replace(".", ",") + " €"}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div className="text-muted" style={{ marginTop: 20 }}>
                (Tale valore potrebbe differire dall'accisa effettivamente
                liquidata. Eventuali mancati versamenti sono da gestire tramite
                ravvedimento operoso presso l'Ufficio delle Dogane)
              </div>

              <hr style={{ marginTop: 50, marginBottom: 30 }} />

              <div
                className="report-card-steps-div-with-inline-input"
                style={{ marginBottom: 20 }}
              >
                <h5>Rateo di acconto</h5>
                <div>
                  <span>
                    Numero di mesi per cui ha valore la presente dichiarazione:
                  </span>
                  <Input
                    type="select"
                    value={rateoAccontoDivisore}
                    onChange={(e) =>
                      setRateoAccontoDivisore(parseInt(e.target.value, 10))
                    }
                  >
                    {MESI.map((_, i) => {
                      const value = i + 1;
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  </Input>
                </div>
              </div>

              <div style={{ marginBottom: 20 }}>
                <div>
                  <span>Pagamento rata semestrale/mensile</span>
                  <ButtonToolbar
                    style={{ display: "inline-flex", marginLeft: 10 }}
                  >
                    <ButtonGroup type="checkbox">
                      <Button
                        size="sm"
                        color={
                          rateoAccontoOgniNumeroMesi === 1 ? "success" : "light"
                        }
                        onClick={() => setRateoAccontoOgniNumeroMesi(1)}
                      >
                        Mensile
                      </Button>
                      <Button
                        size="sm"
                        color={
                          rateoAccontoOgniNumeroMesi === 6 ? "success" : "light"
                        }
                        onClick={() => setRateoAccontoOgniNumeroMesi(6)}
                      >
                        Semestrale
                      </Button>
                      <Button
                        size="sm"
                        color={
                          rateoAccontoOgniNumeroMesi === 12
                            ? "success"
                            : "light"
                        }
                        onClick={() => setRateoAccontoOgniNumeroMesi(12)}
                      >
                        Annuale
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </div>

              {rateoAccontoOgniNumeroMesi === 6 ? (
                <div>
                  {/* due rate! */}
                  <h5>Rate</h5>
                  <div
                    className="report-card-steps-div-with-inline-input"
                    style={{ marginBottom: 5 }}
                  >
                    <span>1° Rata</span>
                    {this.renderPureInput("reportInfo.meseConguaglio", {
                      type: "select",
                      options: [
                        { label: "Gennaio", value: 1 },
                        { label: "Febbraio", value: 2 },
                        { label: "Marzo", value: 3 },
                      ],
                    })}
                  </div>
                  <div className="report-card-steps-div-with-inline-input">
                    <span>2° Rata</span>
                    {this.renderPureInput("reportInfo.meseConguaglio2", {
                      type: "select",
                      options: MESI.map((mString, i) => {
                        const value = i + 1;
                        if (value < 4) {
                          return null;
                        }
                        return { value: value, label: mString };
                      }).filter((x) => x),
                    })}
                  </div>
                </div>
              ) : (
                <div>
                  <h5>Mese di conguaglio</h5>
                  {this.renderPureInput("reportInfo.meseConguaglio", {
                    type: "select",
                    options: [
                      { label: "Gennaio", value: 1 },
                      { label: "Febbraio", value: 2 },
                      { label: "Marzo", value: 3 },
                    ],
                  })}
                </div>
              )}

              <div
                className="report-card-steps-div-with-inline-input"
                style={{ marginTop: 20 }}
              >
                <h5 style={{ display: "inline" }}>Impianto cessato?</h5>
                {this.renderPureInput("reportInfo.impiantoCessato", {
                  type: "select",
                  options: [
                    { value: "", label: "No" },
                    ...MESI.map((mString, i) => {
                      const value = i + 1;
                      return { value: value, label: mString };
                    }),
                  ],
                  showEmptyOption: false,
                })}
              </div>
            </CardBody>
          </Card>
        </Col>
        {askConguaglioRateale2020 && (
          <Col md="12">
            <Card style={{ marginTop: 20 }}>
              <CardHeader>Conguaglio Rateale DL34-2020</CardHeader>
              <CardBody>
                <div>
                  <h5>Scelta adesione al Conguaglio Rateale (DL 34/2020)</h5>

                  {/* {this.renderPureInput('reportInfo.conguaglioRateale2020', {type: 'select', options: [{label: 'SI', value: 1}, {label: 'NO', value: 0}] })} */}
                  {this.renderPureInput("reportInfo.conguaglioRateale2020", {
                    type: "checkbox-radio",
                    radioLabels: ["SI", "NO"],
                  })}
                </div>
                <div style={{ marginTop: 20 }}>
                  <FormGroup row>
                    <Col md="7" lg="8">
                      <h5>Importo sostegno previsto emergenza COVID:</h5>
                    </Col>
                    <Col xs="12" md="5" lg="4">
                      {acconti
                        .reduce((s, v, i) => {
                          // 10% da maggio (5) a settembre (9)
                          if (i >= 5 && i <= 9) {
                            return s + (v || 0) * 0.1;
                          }
                          return s;
                        }, 0)
                        .toFixed(2)
                        .replace(".", ",") + " €"}
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Col md="7" lg="8">
                      <h5 style={{ marginTop: 8 }}>Importo sostegno fruito:</h5>
                    </Col>
                    <Col xs="12" md="5" lg="4">
                      {this.renderPureInput("reportInfo.importoNonPagato", {
                        type: "number",
                        decimalDigits: 2,
                      })}
                    </Col>
                  </FormGroup>

                  <div className="text-muted" style={{ marginTop: 8 }}>
                    Questo importo viene conteggiato nel calcolo dell'accisa a
                    debito riportato nel foglio excel "riepilogo accise".
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        )}

        {askCreditoPregressoNonUtilizzato2021 && (
          <Col md="12">
            <Card style={{ marginTop: 20 }}>
              <CardHeader>Quadro X</CardHeader>
              <CardBody>
                <FormGroup row>
                  <Col md="7" lg="8">
                    <h5>
                      Credito pregresso non utilizzato al 31/12/2021 (Quadro X -
                      rigo 5)
                    </h5>
                  </Col>
                  <Col xs="12" md="5" lg="4">
                    {this.renderPureInput(
                      "reportInfo.creditoPregressoNonUtilizzato",
                      { type: "number", decimalDigits: 2 }
                    )}
                    {this.renderError(
                      "reportInfo.creditoPregressoNonUtilizzato"
                    )}
                  </Col>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        )}

        {askCreditoPregressoNonUtilizzato && (
          <Col md="12">
            <Card style={{ marginTop: 20 }}>
              <CardBody>
                <FormGroup row>
                  <Col md="7" lg="8">
                    <h5 style={{ marginBottom: 0 }}>
                      Credito pregresso non utilizzato al 31/12/{anno - 1}
                    </h5>
                    <i>
                      (Il credito pregresso al 31/12 serve esclusivamente per il
                      calcolo dei crediti sul foglio Excel accise. Il dato
                      suggerito è relativo al credito residuo calcolato dalla
                      dichiarazione dell'anno precedente mediante la formula
                      “Credito rigo Q4 - Sommatoria ratei acconto fino a
                      dicembre”)
                    </i>
                  </Col>
                  <Col xs="12" md="5" lg="4">
                    {this.renderPureInput(
                      "reportInfo.creditoPregressoNonUtilizzato3112",
                      { type: "number", decimalDigits: 2 }
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="7" lg="8">
                    <h5 style={{ marginBottom: 0 }}>
                      Credito pregresso non utilizzato alla data della
                      presentazione della dichiarazione {anno - 1}
                    </h5>
                    <i>
                      (rigo Q6 - inserire l’eventuale credito che alla data di
                      presentazione della dichiarazione si ritiene ancora
                      utilizzabile in quanto non esaurito in detrazione dei
                      precedenti versamenti.)
                    </i>
                  </Col>
                  <Col xs="12" md="5" lg="4">
                    {this.renderPureInput(
                      "reportInfo.creditoPregressoNonUtilizzato",
                      { type: "number", decimalDigits: 2 }
                    )}
                    {this.renderError(
                      "reportInfo.creditoPregressoNonUtilizzato"
                    )}
                  </Col>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
    );
  }

  // per contatori e costanti
  printCodiceError(key) {
    const value = this.getValue(key);
    const ok = value && codiceRegexp.test(value);
    if (ok) {
      return null;
    }
    return (
      <div className="codice-error-container">
        <div className="text-danger">
          Il codice deve iniziare con una lettera e può contenere solo lettere
          (non accentate), numeri e il simbolo _
        </div>
      </div>
    );
  }

  removeContatore(contatore, indexInReportInfoContatori, rowIndex) {
    const contatori = this.getValue("reportInfo.contatori") || [];
    let newState = { ...this.state };
    if (contatore.id) {
      // add it to the removed
      newState.contatoriRemoved.push({
        index: rowIndex,
        contatore: contatore,
      });
      newState.contatoriRemoved = sortBy(newState.contatoriRemoved, "index");
    }
    const newContatori = [...contatori];
    newContatori.splice(indexInReportInfoContatori, 1);
    newState = set(newState, "data.reportInfo.contatori", newContatori);
    this.setState(newState);
  }

  addRemovedContatore(contatore, indexInReportInfoContatori, indexInRemoved) {
    const contatori = this.getValue("reportInfo.contatori") || [];
    let newState = { ...this.state };
    newState.contatoriRemoved = [...newState.contatoriRemoved];
    newState.contatoriRemoved.splice(indexInRemoved, 1);
    const newContatori = [...contatori];
    newContatori.splice(indexInReportInfoContatori, 0, contatore);
    newState = set(newState, "data.reportInfo.contatori", newContatori);
    this.setState(newState);
  }

  renderTabellaContatatori() {
    const { installation } = this.props;
    const installationType = installationsByType[installation.type];

    const showCode = !!installationType.necessitaAlgoritmo;
    let tipiDiContatori = installationType.tipiDiContatori;

    if (
      installation.type === "CESSIONE_TOTALE" &&
      this.getValue("reportInfo.haContatoriDiversi")
    ) {
      tipiDiContatori = tipiDiContatoriWithCeduta;
    }

    const contatori = this.getValue("reportInfo.contatori") || [];

    let rows;

    if (contatori.length || this.state.contatoriRemoved.length) {
      rows = [];

      const addRow = (contatore, isRemoved, rowIndex, index, removedIndex) => {
        const baseKey = "reportInfo.contatori." + index;

        const typeObj = find(
          tipiDiContatori,
          (t) => t.value === contatore.type
        );

        rows.push(
          <tr key={rowIndex} className={isRemoved ? "removed-row" : ""}>
            {showCode && (
              <td>
                {isRemoved
                  ? contatore.codice
                  : this.renderPureInput(baseKey + ".codice", { type: "text" })}
                {!isRemoved && this.printCodiceError(baseKey + ".codice")}
              </td>
            )}
            <td>
              {isRemoved
                ? contatore.matricola
                : contatore.id
                ? contatore.matricola
                : this.renderPureInput(baseKey + ".matricola", {
                    type: "text",
                  })}
            </td>
            <td>
              {isRemoved
                ? typeObj && typeObj.label
                : this.renderPureInput(baseKey + ".type", {
                    type: "select",
                    options: tipiDiContatori,
                    disabled: contatore.id ? true : false,
                    showEmptyOption: false,
                  })}
            </td>
            <td>
              <Button
                color="link"
                onClick={() =>
                  isRemoved
                    ? this.addRemovedContatore(contatore, index, removedIndex)
                    : this.removeContatore(contatore, index, rowIndex)
                }
                style={{ padding: 0 }}
              >
                {isRemoved ? "+" : "×"}
              </Button>
            </td>
          </tr>
        );
      };

      var index = 0;
      var removedIndex = 0;
      for (
        let rowIndex = 0;
        index < contatori.length ||
        removedIndex < this.state.contatoriRemoved.length;
        rowIndex++
      ) {
        let contatore;
        let isRemoved = false;
        if (
          this.state.contatoriRemoved[removedIndex] &&
          this.state.contatoriRemoved[removedIndex].index === rowIndex
        ) {
          contatore = this.state.contatoriRemoved[removedIndex].contatore;
          isRemoved = true;
        } else {
          contatore = contatori[index];
        }

        addRow(contatore, isRemoved, rowIndex, index, removedIndex);

        if (isRemoved) {
          removedIndex++;
        } else {
          index++;
        }
      }
    } else {
      rows = (
        <tr key="no-rows">
          <td colSpan={3 + (showCode ? 1 : 0)}>
            Non hai ancora aggiunto nessun contatore per questo impianto
          </td>
        </tr>
      );
    }

    return (
      <table className="table table-sm report-card-table-lista-contatori">
        <thead>
          <tr>
            {showCode && <th>Codice</th>}
            <th>Matricola</th>
            <th>Tipo</th>
            <th style={{ width: 30 }}></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }

  addContatore = () => {
    const contatori = this.getValue("reportInfo.contatori") || [];
    const newContatore = { type: "produzione" };
    const newContatori = [...contatori, newContatore];
    this.setValue("reportInfo.contatori", newContatori);
  };

  renderTabellaCostanti() {
    const costanti = this.getValue("reportInfo.costanti") || [];

    let rows;
    if (costanti.length) {
      rows = costanti.map((costante, index) => {
        const baseKey = "reportInfo.costanti." + index;

        return (
          <tr key={index}>
            <td>
              {this.renderPureInput(baseKey + ".codice", { type: "text" })}
              {this.printCodiceError(baseKey + ".codice")}
            </td>
            <td>
              {this.renderPureInput(baseKey + ".value", {
                type: "number",
                decimalDigits: null,
              })}
              {this.renderError(baseKey + ".value")}
            </td>
            <td className="proporzionale-checkbox">
              {this.renderPureInput(baseKey + ".proporzionale", {
                type: "checkbox",
              })}
            </td>
            <td>
              <Button
                color="link"
                onClick={() => this.removeCostante(costante, index)}
                style={{ padding: 0 }}
              >
                {"×"}
              </Button>
            </td>
          </tr>
        );
      });
    } else {
      rows = (
        <tr key="no-rows">
          <td colSpan={4}>Non hai ancora aggiunto nessun valore forfait</td>
        </tr>
      );
    }

    return (
      <table className="table table-sm report-card-table-lista-costanti">
        <thead>
          <tr>
            <th>Codice</th>
            <th>Valore</th>
            <th style={{ width: 140 }}>
              Proporzionale
              <br />
              <small>se non mese intero</small>
            </th>
            <th style={{ width: 30 }}></th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }

  addCostante = () => {
    const costanti = this.getValue("reportInfo.costanti") || [];
    const newCostante = {};
    const newCostanti = [...costanti, newCostante];
    this.setValue("reportInfo.costanti", newCostanti);
  };

  removeCostante = (constante, index) => {
    const costanti = this.getValue("reportInfo.costanti") || [];
    const newCostanti = [...costanti];
    newCostanti.splice(index, 1);
    this.setValue("reportInfo.costanti", newCostanti);
  };

  onFormulaChange(index, what, value) {
    this.setValue("reportInfo.formule." + index + "." + what, value || "");
  }

  _renderFormulaParsingStatus(obj) {
    let content = null;

    if (obj.errors.length) {
      content = (
        <ul>
          {obj.errors.map((error, index) => {
            return (
              <li
                key={index}
                className={
                  error.type === "error" ? "text-danger" : "text-warning"
                }
              >
                {error.message}
              </li>
            );
          })}
        </ul>
      );
    } else if (obj.formula && obj.formula.length) {
      content = <div className="text-success">Formula corretta</div>;
    }

    return content;
  }

  getFormulaParsingStatus(formule, context) {
    const anno = this.getValue("anno");

    const result = analyzeFormule(anno, formule, context);

    return mapValues(result, (v) => this._renderFormulaParsingStatus(v));
  }

  renderAskSiONo(index, key) {
    const { installation } = this.props;
    const baseKey = "reportInfo.formule." + index;
    const formulaObj = this.getValue(baseKey);

    let checked = typeof formulaObj[key] === "string";

    const setChecked = (c) => {
      this.setValue(baseKey + "." + key, c ? "" : null);
    };

    return (
      <ButtonToolbar style={{ display: "inline-flex", marginLeft: 10 }}>
        <ButtonGroup type="checkbox">
          <Button
            size="sm"
            color={checked ? "success" : "light"}
            onClick={() => setChecked(true)}
          >
            Sì
          </Button>
          <Button
            size="sm"
            color={!checked ? "danger" : "light"}
            onClick={() => setChecked(false)}
          >
            No
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
    );
  }

  renderIsUsiCommercialiENumberoUtenze(index, formulaType) {
    const baseKey = "reportInfo.formule." + index;
    const formulaObj = this.getValue(baseKey);

    const infoKeys = formuleVariablesKeysPerInfo[formulaType];
    const { numeroUtenzeKey, isUsiCommercialiKey, defaults } = infoKeys;

    const numeroUtenze = _getValueOrDefault(
      formulaObj,
      numeroUtenzeKey,
      defaults.numeroUtenze
    );
    const isUsiCommerciali = _getValueOrDefault(
      formulaObj,
      isUsiCommercialiKey,
      defaults.isUsiCommerciali
    );

    const setIsUsiCommerciali = (c) => {
      const newObj = {
        ...formulaObj,
        [isUsiCommercialiKey]: c,
        [numeroUtenzeKey]: c ? numeroUtenze : 0,
      };
      this.setValue(baseKey, newObj);
    };

    return (
      <div>
        <FormGroup row>
          <Col md="3" lg="3">
            <span>Usi commerciali:</span>
          </Col>
          <Col xs="12" md="9" lg="9">
            <ButtonToolbar style={{ display: "inline-flex", marginLeft: 10 }}>
              <ButtonGroup type="checkbox">
                <Button
                  size="sm"
                  color={isUsiCommerciali ? "success" : "light"}
                  onClick={() => setIsUsiCommerciali(true)}
                >
                  Sì
                </Button>
                <Button
                  size="sm"
                  color={!isUsiCommerciali ? "danger" : "light"}
                  onClick={() => setIsUsiCommerciali(false)}
                >
                  No
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </FormGroup>
        {isUsiCommerciali && (
          <FormGroup row>
            <Col md="3" lg="3">
              <span>Numero utenze:</span>
            </Col>
            <Col xs="12" md="9" lg="9">
              <NumberInput
                value={numeroUtenze}
                onChange={(event, value) =>
                  this.setValue(baseKey + "." + numeroUtenzeKey, value)
                }
                storeNumberAsString={false}
                decimalDigits={0}
                min={0}
              />
            </Col>
          </FormGroup>
        )}
      </div>
    );
  }

  renderFormula() {
    const anno = this.getValue("anno");
    const formule = this.getValue("reportInfo.formule") || [];
    const contatori = this.getValue("reportInfo.contatori");
    const costanti = this.getValue("reportInfo.costanti");

    if (formule.length === 0) {
      const newFormule = [
        {
          startDate: anno - 1 + "-01-01",
          endDate: anno - 1 + "-12-31",
        },
      ];
      this.setValue("reportInfo.formule", newFormule);
      return null;
    }

    const context = {
      contatori: contatori,
      costanti: costanti,
    };

    let hasConsumiNonSottoposti = false;

    const formuleElements = formule.map((formulaObj, index) => {
      const disabledDays = {
        before: moment(formulaObj.startDate, "YYYY-MM-DD").toDate(),
        after: new Date(anno - 1, 11, 31),
      };

      const parsingStatus = this.getFormulaParsingStatus(formulaObj, context);

      hasConsumiNonSottoposti =
        hasConsumiNonSottoposti ||
        typeof formulaObj.formulaNonSottoposti === "string";

      const renderFormulaDiv = (
        title,
        formulaKey,
        formulaName,
        help = null,
        askUsiCommerciali = true,
        quadro = null
      ) => {
        return (
          <div style={{ marginTop: 20, width: "100%" }}>
            <Col md="12">
              <h6 style={{ fontWeight: "bold" }}>
                {title} {this.renderAskSiONo(index, formulaKey)}
              </h6>
              {help && (
                <span
                  className="text-muted"
                  style={{
                    fontSize: 10,
                    display: "block",
                    marginTop: -8,
                    marginBottom: 15,
                  }}
                >
                  {help}
                </span>
              )}
            </Col>

            {typeof formulaObj[formulaKey] === "string" && (
              <div style={{ marginLeft: 40 }}>
                {askUsiCommerciali && (
                  <Col md="12">
                    {this.renderIsUsiCommercialiENumberoUtenze(
                      index,
                      formulaName
                    )}
                  </Col>
                )}

                <Col md="12">
                  <FormGroup row>
                    <Col md="3" lg="3">
                      <code>{formulaName}</code>
                    </Col>
                    <Col xs="12" md="9" lg="9">
                      <FunctionEditor
                        context={context}
                        variable={formulaName}
                        functionString={formulaObj[formulaKey]}
                        onChange={(value) =>
                          this.onFormulaChange(index, formulaKey, value)
                        }
                        minLines={3}
                        maxLines={5}
                        width="100%"
                        disabled={false}
                      />
                      {this.renderError(
                        "reportInfo.formule." + index + "." + formulaKey
                      )}
                      {parsingStatus[formulaName]}
                    </Col>
                  </FormGroup>
                </Col>

                {quadro === "I" && (
                  <Col md="12">
                    <Row>
                      {this.renderInput(
                        "Codice identificativo officina distributrice",
                        "reportInfo.codiceIdentificativoOfficinaDistributrice",
                        { horizontal: true, type: "text", disabled: false }
                      )}
                    </Row>
                    <Row>
                      {this.renderInput(
                        "Tipologia fornitura",
                        "reportInfo.tipologiaFornitura",
                        {
                          horizontal: true,
                          type: "select",
                          disabled: false,
                          options: tipologieFornituraSelect,
                        }
                      )}
                    </Row>
                  </Col>
                )}
              </div>
            )}
          </div>
        );
      };

      return (
        <Row key={index}>
          <Col md="12">
            <FormGroup row>
              <Col md="3" lg="3">
                <Label
                  htmlFor={
                    "input-" + "reportInfo.formule." + index + ".startDate"
                  }
                >
                  Valida da:
                </Label>
              </Col>
              <Col xs="12" md="4" lg="4">
                {this.renderPureInput(
                  "reportInfo.formule." + index + ".startDate",
                  { horizontal: true, type: "date", disabled: true }
                )}
                {this.renderError("reportInfo.formule." + index + ".startDate")}
              </Col>
            </FormGroup>
          </Col>
          <Col md="12">
            <FormGroup row>
              <Col md="3" lg="3">
                <Label
                  htmlFor={
                    "input-" + "reportInfo.formule." + index + ".startDate"
                  }
                >
                  Valida a:
                </Label>
              </Col>
              <Col xs="12" md="4" lg="4">
                {this.renderPureInput(
                  "reportInfo.formule." + index + ".endDate",
                  { horizontal: true, type: "date", disabledDays: disabledDays }
                )}
                {this.renderError("reportInfo.formule." + index + ".endDate")}
              </Col>
            </FormGroup>
          </Col>

          <Col md="12">
            <strong>Formule:</strong>
          </Col>

          {renderFormulaDiv(
            "Quadro I - Energia elettrica fatturata:",
            "formulaEnergiaFatturata",
            "ENERGIA_ELETTRICA_FATTURATA",
            null,
            false,
            "I"
          )}

          {renderFormulaDiv(
            "Quadro J - Consumi non sottoposti:",
            "formulaNonSottoposti",
            "CONSUMI_NON_SOTTOPOSTI"
          )}

          {renderFormulaDiv(
            "Quadro L - Consumi esenti - L5:",
            "formulaEsentiL5",
            "CONSUMI_ESENTI_L5",
            //help
            "L5 - Energia elettrica utilizzata per l’attività di produzione di elettricità e per mantenere la capacità di produrre elettricità"
          )}

          {renderFormulaDiv(
            "Quadro L - Consumi esenti - L6:",
            "formulaEsentiL6",
            "CONSUMI_ESENTI_L6",
            //help
            "L6 - Energia elettrica prodotta con impianti azionati da fonti rinnovabili ai sensi della normativa vigente in materia, con potenza disponibile superiore a 20 kW, consumata dalle imprese di autoproduzione in locali e luoghi diversi dalle abitazioni"
          )}

          {renderFormulaDiv(
            "Quadro L - Consumi esenti - L8:",
            "formulaEsentiL8",
            "CONSUMI_ESENTI_L8",
            //help
            "L8 - Impieghi per l’impianto e l’esercizio delle linee di trasporto urbano e interurbano"
          )}

          {renderFormulaDiv(
            "Quadro M - Consumi assoggettati - M1:",
            "formulaAssoggettatiM1",
            "CONSUMI_ASSOGGETTATI_M1",
            //help
            "Abitazioni - Di residenza anagrafica"
          )}

          {renderFormulaDiv(
            "Quadro M - Consumi assoggettati - M2:",
            "formulaAssoggettatiM2",
            "CONSUMI_ASSOGGETTATI_M2",
            //help
            "Abitazioni - Seconde case"
          )}

          {renderFormulaDiv(
            "Quadro M - Consumi assoggettati - M3:",
            "formulaAssoggettatiM3",
            "CONSUMI_ASSOGGETTATI_M3",
            //help
            "Abitazioni - Recupero di imposta articolo 52 comma 3 lettera 'e' D.Lgs. 504/95"
          )}

          {renderFormulaDiv(
            "Quadro M - Consumi assoggettati - M9-M10-M11-M12:",
            "formulaAssoggettati",
            "CONSUMI_ASSOGGETTATI",
            //help
            "Loc./luoghi div.abit.(dal 01/06/2012)"
          )}

          {renderFormulaDiv(
            "Perdite:",
            "formulaPerdite",
            "PERDITE",
            null,
            false
          )}
        </Row>
      );
    });
    return (
      <div className="formule-editor-container">
        {formuleElements}

        {hasConsumiNonSottoposti && (
          <div>
            <Col md={12}>
              <FormGroup row>
                <Col md="3" lg="3">
                  <Label>Tipologia di consumo</Label>
                </Col>
                <Col xs="12" md="9" lg="9">
                  {this.renderPureInput("reportInfo.tipologiaDiConsumo", {
                    type: "select",
                    options: tipologieDiConsumoSelect,
                  })}
                  {this.renderError("reportInfo.tipologiaDiConsumo")}
                </Col>
              </FormGroup>
            </Col>
          </div>
        )}
      </div>
    );
  }

  setHaContatoriDiversi = (t) => {
    this.setValue("reportInfo.haContatoriDiversi", t);
  };

  renderStepImpianto() {
    const { installation, isAdmin, isPartner, isRelax } = this.props;
    const installationType = installationsByType[installation.type];
    const necessitaAlgoritmo = !!installationType.necessitaAlgoritmo;

    const documentiDaCaricare = (
      <div>
        - Copia Registro delle letture dei contatori elettrici (o documentazione
        analoga)
      </div>
    );

    const sendPdfOnly = !isAdmin && isRelax;

    // only show dichiarazione files
    const dichiarazione = this.props.dichiarazione || null;
    const showEverything = !dichiarazione;

    const haContatoriDiversi = this.getValue("reportInfo.haContatoriDiversi");

    const tipologiaDiConsumoEsente = this.getValue(
      "reportInfo.tipologiaDiConsumoEsente"
    );
    const tipologiaDiConsumoEsenteObject =
      find(
        tipologieDiConsumoEsente,
        (x) => x.value === tipologiaDiConsumoEsente
      ) || tipologieDiConsumoEsente[0];

    return (
      <div>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>Dati impianto</CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <div className="installation-name-container">
                      {installation.name ||
                        "Impianto " + installation.installationCode}
                    </div>
                  </Col>
                  <Col sm="12">
                    <div className="license-container">
                      Codice ditta/Licenza:{" "}
                      <span className="license-name">
                        {installation.installationCode}
                      </span>
                    </div>
                    <div className="type-container">
                      Tipologia:{" "}
                      <span className="type-name">{installationType.name}</span>
                    </div>
                    <div className="power-container">
                      Potenza nominale:{" "}
                      <span className="power-kw">
                        {installation.powerWatts == null
                          ? "-"
                          : numeral(
                              installation.powerWatts / 1000
                            ).format()}{" "}
                        kW
                      </span>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        {(sendPdfOnly || isAdmin) && (
          <Row>
            <Col md="12">
              <Card>
                {isAdmin && <CardHeader>Per Admin</CardHeader>}

                <CardBody>
                  <Row>
                    {sendPdfOnly ? (
                      <Col>
                        <Alert color="success">
                          Hai acquistato l'assistenza alla compilazione. Ti
                          basterà caricare qui i seguenti documenti e penseremo
                          a tutto noi:
                          <br />
                          {documentiDaCaricare}
                        </Alert>

                        {this.renderFileInput("files")}
                        {this.renderError("files")}
                      </Col>
                    ) : (
                      <Col>
                        {/* <Alert color="info">
                        Carica i seguenti documenti
                        <br />
                        {documentiDaCaricare}
                      </Alert> */}

                        {isAdmin && (
                          <div style={{}}>
                            <div>File caricati</div>
                            {this.renderFileListForAdmin("files")}
                          </div>
                        )}

                        {/* {this.renderFileInput("files", {
                        postUrlQuery: isAdmin
                          ? "?userId=" + installation.user_id
                          : undefined,
                      })} */}
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {dichiarazione && !sendPdfOnly && (
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>Carica file dichiarazione</CardHeader>
                <CardBody>{this.renderDichiarazione()}</CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {showEverything && !sendPdfOnly && (
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>Contatori</CardHeader>
                <CardBody>
                  {installation.type === "CESSIONE_TOTALE" && (
                    <Row style={{ marginBottom: 30 }}>
                      <Col sm="12">
                        Il contatore di cessione è diverso da quello di
                        produzione?
                        <ButtonToolbar
                          style={{ display: "inline-flex", marginLeft: 10 }}
                        >
                          <ButtonGroup type="checkbox">
                            <Button
                              size="sm"
                              color={haContatoriDiversi ? "primary" : "light"}
                              onClick={() => this.setHaContatoriDiversi(true)}
                            >
                              Sì
                            </Button>
                            <Button
                              size="sm"
                              color={!haContatoriDiversi ? "primary" : "light"}
                              onClick={() => this.setHaContatoriDiversi(false)}
                            >
                              No
                            </Button>
                          </ButtonGroup>
                        </ButtonToolbar>
                        <div className="text-muted">
                          (se diversi vengono quantificate le perdite)
                        </div>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col sm="12">{this.renderTabellaContatatori()}</Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      {this.renderError("reportInfo.contatori.error")}
                    </Col>
                    <Col sm="12">
                      <Button
                        color="secondary"
                        outline
                        onClick={this.addContatore}
                      >
                        Aggiungi contatore
                      </Button>
                    </Col>
                  </Row>
                  {chiediFrequenzeLetture(installation) && (
                    <Row
                      style={{
                        maxWidth: 340,
                        marginTop: 30,
                      }}
                    >
                      {this.renderInput(
                        "Frequenza letture",
                        "reportInfo.frequenzaLetture",
                        {
                          type: "select",
                          options: frequenzeLetture,
                          disabled: false,
                          showEmptyOption: false,
                          horizontal: true,
                        }
                      )}
                    </Row>
                  )}
                  {installation.type ===
                    "CESSIONE_PARZIALE_NON_COMMERCIALE" && (
                    <Row style={{ marginTop: 30 }}>
                      {this.renderInput(
                        "Tipologia di consumo esente",
                        "reportInfo.tipologiaDiConsumoEsente",
                        {
                          horizontal: false,
                          type: "select",
                          options: tipologieDiConsumoEsente,
                          showEmptyOption: false,
                        }
                      )}
                      <Col md="12" className="text-muted">
                        {tipologiaDiConsumoEsenteObject.help}
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {showEverything && !sendPdfOnly && necessitaAlgoritmo && (
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>Valori forfait</CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">{this.renderTabellaCostanti()}</Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Button
                        color="secondary"
                        outline
                        onClick={this.addCostante}
                      >
                        Aggiungi valore forfait
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {showEverything && !sendPdfOnly && necessitaAlgoritmo && (
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>Formule</CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">{this.renderFormula()}</Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    );
  }

  getRiepilogoInfo = memoizeOne((installation, serviceLicense, _report) => {
    const { isAdmin, isRelax } = this.props; // non cambia, quindi non fa niente se è all'interno di memoize
    let report = cloneDeep(_report);
    try {
      if (report.reportInfo.statoReport) {
        delete report.reportInfo.statoReport;
      }
      const validationResult = validateReport(
        report,
        true,
        isRelax ? FAKE_SERVICE_TRUE : FAKE_SERVICE_FALSE,
        isAdmin
      );
    } catch (err) {
      report = _report;
    }
    const riepilogoInfo = _getRiepilogoInfo(
      installation,
      serviceLicense,
      report
    );
    const riepilogoInfoSenzaOverridePerQuadro = {};
    const quadri = ["J", "L", "M", "Q"];

    if (report.reportInfo.overrideQuadri) {
      const reportSenzaOverride = {
        ...report,
        reportInfo: {
          ...report.reportInfo,
          overrideQuadri: null,
        },
      };
      const riepilogoInfoSenzaOverride = _getRiepilogoInfo(
        installation,
        serviceLicense,
        reportSenzaOverride
      );
      quadri.forEach((q) => {
        let riepilogoInfoSenzaOverride2 = riepilogoInfoSenzaOverride;
        if (q === "Q") {
          if (report.reportInfo.overrideQuadri.M) {
            // va ricalcolato con l'M giusto!
            const reportSenzaOverrideQuadroQ = {
              ...report,
              reportInfo: {
                ...report.reportInfo,
                overrideQuadri: {
                  ...report.reportInfo.overrideQuadri,
                  Q: null,
                },
              },
            };
            riepilogoInfoSenzaOverride2 = _getRiepilogoInfo(
              installation,
              serviceLicense,
              reportSenzaOverrideQuadroQ
            );
          }
        }
        riepilogoInfoSenzaOverridePerQuadro[q] = riepilogoInfoSenzaOverride2;
      });
    } else {
      quadri.forEach((q) => {
        riepilogoInfoSenzaOverridePerQuadro[q] = riepilogoInfo;
      });
    }

    return {
      riepilogoInfo,
      riepilogoInfoSenzaOverridePerQuadro,
    };
  });

  renderStepRiepilogoQuadro(quadroSelezionato) {
    const { installation, serviceLicenses, isAdmin, isRelax } = this.props;

    const serviceLicense = find(
      serviceLicenses,
      (s) => s.id === installation.license_id
    );
    const report = this.state.data;

    const { riepilogoInfo, riepilogoInfoSenzaOverridePerQuadro } =
      this.getRiepilogoInfo(installation, serviceLicense, report);
    console.log(riepilogoInfo, riepilogoInfoSenzaOverridePerQuadro);

    const sendPdfOnly = !isAdmin && isRelax;

    const overrideQuadriKey = `reportInfo.overrideQuadri.${quadroSelezionato}`;
    const isEditingValoriMensili = this.getValue(overrideQuadriKey);

    const shouldShowQuadro = (q) => {
      return q.startsWith(quadroSelezionato);
    };

    let haQuadroLNegativo = false;

    return (
      <div>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>Quadro {quadroSelezionato}</CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12">
                    {riepilogoInfo && (
                      <div className="report-riepilogo">
                        <div className="quadri-mensili-container">
                          <table>
                            <thead>
                              <tr>
                                <th>Mese</th>
                                {map(
                                  riepilogoInfo.tabellaMensili,
                                  (mesiPerRigoQuadro, quadro) => {
                                    if (!shouldShowQuadro(quadro)) {
                                      return null;
                                    }
                                    if (quadro === "Q") {
                                      return (
                                        <th key={quadro}>
                                          {quadro}
                                          {quadro === "Q" ? " - Acconti" : ""}
                                        </th>
                                      );
                                    }
                                    return map(
                                      mesiPerRigoQuadro,
                                      (mesi, keyRigo) => {
                                        const rigo = keyRigo.replace(
                                          "rigaQuadro",
                                          ""
                                        );
                                        return (
                                          <th key={quadro + rigo}>
                                            {quadro + rigo}
                                          </th>
                                        );
                                      }
                                    );
                                  }
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {MESI.map((meseString, index) => {
                                const mese = index + 1;
                                return (
                                  <tr key={mese}>
                                    <td className="td-mese">{meseString}</td>
                                    {map(
                                      riepilogoInfo.tabellaMensili,
                                      (mesiPerRigoQuadro, quadro) => {
                                        if (
                                          isEditingValoriMensili &&
                                          riepilogoInfoSenzaOverridePerQuadro[
                                            quadro
                                          ]
                                        ) {
                                          mesiPerRigoQuadro =
                                            riepilogoInfoSenzaOverridePerQuadro[
                                              quadro
                                            ].tabellaMensili[quadro];
                                        }
                                        if (!shouldShowQuadro(quadro)) {
                                          return null;
                                        }

                                        if (quadro === "Q") {
                                          mesiPerRigoQuadro = {
                                            "": mesiPerRigoQuadro,
                                          };
                                        }

                                        return map(
                                          mesiPerRigoQuadro,
                                          (mesi, keyRigo) => {
                                            const obj = mesi && mesi[mese];

                                            if (isEditingValoriMensili) {
                                              const preKey = keyRigo
                                                ? `${quadro}.${keyRigo}`
                                                : quadro;
                                              const key = `reportInfo.overrideQuadri.${preKey}.mese${mese}`;
                                              const overridedValue =
                                                this.getValue(key);
                                              const computedValue =
                                                obj && obj.totale;
                                              const invalid =
                                                typeof overridedValue ===
                                                  "number" &&
                                                computedValue !==
                                                  overridedValue;
                                              if (
                                                quadro === "L" &&
                                                (typeof overridedValue ===
                                                "number"
                                                  ? overridedValue
                                                  : computedValue) < 0
                                              ) {
                                                haQuadroLNegativo = true;
                                              }
                                              return (
                                                <td
                                                  key={quadro}
                                                  className="td-totale td-editing"
                                                >
                                                  <NumberInput
                                                    value={
                                                      typeof overridedValue ===
                                                      "number"
                                                        ? overridedValue
                                                        : computedValue
                                                    }
                                                    onChange={(event, value) =>
                                                      this.setValue(key, value)
                                                    }
                                                    //TODO: ??? onPaste: e => this.onPasteLetture(index, lettureTableRows, rowIndex, e)
                                                    storeNumberAsString={false}
                                                    decimalDigits={2}
                                                    min={0}
                                                    invalid={invalid}
                                                  />
                                                </td>
                                              );
                                            }

                                            if (
                                              quadro === "L" &&
                                              obj &&
                                              obj.totale < 0
                                            ) {
                                              haQuadroLNegativo = true;
                                            }

                                            var totaleString = obj
                                              ? numeral(obj.totale).format(
                                                  obj.format
                                                ) +
                                                " " +
                                                obj.unit
                                              : "";
                                            return (
                                              <td
                                                key={quadro}
                                                className="td-totale"
                                              >
                                                {totaleString}
                                              </td>
                                            );
                                          }
                                        );
                                      }
                                    )}
                                  </tr>
                                );
                              })}
                              <tr className="tr-totale">
                                <td className="td-mese">TOTALE</td>
                                {map(
                                  riepilogoInfo.tabellaMensili,
                                  (mesiPerRigoQuadro, quadro) => {
                                    if (!shouldShowQuadro(quadro)) {
                                      return null;
                                    }

                                    if (quadro === "Q") {
                                      mesiPerRigoQuadro = {
                                        "": mesiPerRigoQuadro,
                                      };
                                    }

                                    return map(
                                      mesiPerRigoQuadro,
                                      (mesi, keyRigo) => {
                                        const obj = mesi && mesi.totale;
                                        var totaleString = obj
                                          ? numeral(obj.totale).format(
                                              obj.format
                                            ) +
                                            " " +
                                            obj.unit
                                          : "";
                                        return (
                                          <td
                                            key={quadro}
                                            className="td-totale"
                                          >
                                            {totaleString}
                                          </td>
                                        );
                                      }
                                    );
                                  }
                                )}
                              </tr>
                            </tbody>
                          </table>

                          <div style={{ marginTop: 8 }}>
                            {isEditingValoriMensili ? (
                              <Button
                                size="sm"
                                color="primary"
                                outline
                                onClick={() => {
                                  this.setValue(overrideQuadriKey, null);
                                }}
                              >
                                Ripristina valori calcolati
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                color="primary"
                                outline
                                onClick={() => {
                                  this.setValue(overrideQuadriKey, {});
                                }}
                              >
                                Modifica valori manualmente
                              </Button>
                            )}
                          </div>

                          {haQuadroLNegativo && (
                            <div style={{ marginTop: 8 }}>
                              <Row style={{ maxWidth: 500 }}>
                                <Col sm="12">Alcuni valori sono negativi.</Col>
                                {this.renderInput(
                                  "Conferma la validità dei consumi",
                                  "reportInfo.allowNegativoL",
                                  {
                                    horizontal: true,
                                    type: "checkbox",
                                    disabled: false,
                                  }
                                )}
                              </Row>
                            </div>
                          )}

                          {isEditingValoriMensili && (
                            <Alert
                              color="warning"
                              style={{ fontWeight: "initial", fontSize: 14 }}
                            >
                              I valori modificati sono indicati in rosso. <br />
                              Poi cancellare il valore per ripristinare il
                              valore calcolato.
                            </Alert>
                          )}
                        </div>
                      </div>
                    )}

                    {!riepilogoInfo && sendPdfOnly && (
                      <div>
                        <Alert color="success">
                          Hai acquistato l'assistenza alla compilazione. Da qui
                          ci pensiamo noi.
                        </Alert>
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  renderStepElencoPropriFornitori() {
    return <ReportStepQuadroEF parent={this} />;
  }

  renderStepFinale() {
    const { installation, serviceLicenses, isAdmin } = this.props;

    const serviceLicense = find(
      serviceLicenses,
      (s) => s.id === installation.license_id
    );
    const report = this.state.data;

    const { riepilogoInfo } = this.getRiepilogoInfo(
      installation,
      serviceLicense,
      report
    );
    console.log(riepilogoInfo);

    return (
      <div>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>Riepilogo</CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12">
                    {riepilogoInfo && (
                      <div className="report-riepilogo">
                        <div className="report-riepilogo-frontespizio">
                          <div className="tipo-dichiarante">
                            {riepilogoInfo.info.tipoDichiarante}
                          </div>
                          <div className="periodo-anno">
                            <span>
                              Periodo: anno {riepilogoInfo.info.periodoAnno}
                            </span>
                          </div>
                          <div className="dichiarante-info">
                            <span className="info-title">Codice ditta: </span>
                            <span className="info-value">
                              {riepilogoInfo.info.codiceDitta}
                            </span>
                            <br />
                            <span className="info-title">Denominazione: </span>
                            <span className="info-value">
                              {riepilogoInfo.info.denominazione}
                            </span>
                            <br />
                            <span className="info-title">Comune: </span>
                            <span className="info-value">
                              {riepilogoInfo.info.comune}
                            </span>
                            <br />
                            <span className="info-title">Indirizzo: </span>
                            <span className="info-value">
                              {riepilogoInfo.info.indirizzo}
                            </span>
                          </div>
                        </div>
                        <div className="quadri-container">
                          <table>
                            <tbody>
                              {riepilogoInfo.quadri.map((obj, index) => {
                                var totaleString =
                                  numeral(obj.totale).format(obj.format) +
                                  " " +
                                  obj.unit;
                                return (
                                  <tr key={index}>
                                    <td className="td-quadro">
                                      Quadro {obj.quadro}
                                    </td>
                                    <td className="td-quadro-title">
                                      {obj.quadroTitle}
                                    </td>
                                    <td className="td-totale">
                                      {totaleString}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  selectWizardStep(index) {
    this._loki &&
      this._loki.setState({
        currentStep: index + 1,
      });
  }

  getWizardSteps() {
    const {
      installation,
      isAdmin,
      isPartner,
      isRelax,
      isClienteFinaleWebsite,
    } = this.props;
    const installationType = installationsByType[installation.type];
    const report = this.state.data;

    const chiedeDatiAcciseAnnoPrecedente =
      installationType.chiedeDatiAcciseAnnoPrecedente;

    const sendPdfOnly = !isClienteFinaleWebsite && !isAdmin && isRelax;

    // only show dichiarazione files
    const dichiarazione = this.props.dichiarazione || null;
    const showEverything = !dichiarazione;

    const contatori = this.getValue("reportInfo.contatori") || [];
    const steps = [];

    if (!isClienteFinaleWebsite) {
      steps.push({
        label: "Impianto",
        caption: "Dettagli impianto",
        type: "impianto",
      });
    }

    if (showEverything && !sendPdfOnly) {
      if (contatori.length) {
        contatori.forEach((contatore, index) => {
          steps.push({
            label: this.getContatoreTitle(index),
            caption: this.getContatoreSubtitle(index),
            type: "contatore",
            contatoreIndex: index,
          });
        });
      }

      // step tutte le letture!
      steps.push({
        label: "Letture",
        caption: "",
        type: "letture",
      });

      if (chiedeDatiAcciseAnnoPrecedente) {
        steps.push({
          label: "Accise",
          caption: "",
          type: "accise",
        });
      }

      // nuovi tab per riepilogo e override quadri
      if (!isClienteFinaleWebsite) {
        const quadri = ["J", "L", "M"];
        let hasQuadroM = false;
        quadri.forEach((quadro) => {
          if (haQuadro(quadro, installation.type, report)) {
            if (quadro === "M") {
              hasQuadroM = true;
            }
            steps.push({
              label: "Quadro " + quadro,
              caption: "",
              type: "riepilogoQuadri",
              quadro: quadro,
            });
          }
        });
        if (hasQuadroM) {
          steps.push({
            label: "Quadro Q",
            caption: "",
            type: "riepilogoQuadri",
            quadro: "Q",
          });
        }
      }
    }

    const anno = this.getValue("anno");

    if (installationType.chiediQuadroEF && anno - 1 >= 2023) {
      // 13. ALLEGATO – ELENCO PROPRI FORNITORI E CEDENTI
      // https://www.adm.gov.it/portale/documents/20182/150234231/20231227+-+797116+RU+-+Circolare+30+-+Allegato+1.pdf/52bc3e19-acff-c656-5c04-0cda990b1f03?t=1704217464327
      steps.push({
        label: "Quadro EF",
        caption: "Elenco propri fornitori/cedenti",
        type: "elencoPropriFornitori",
        quadro: "EF",
      });
    }

    if (!isClienteFinaleWebsite) {
      steps.push({
        label: "Riepilogo",
        caption: "",
        type: "end",
      });
    }

    return steps;
  }

  validateAndGetErrors(path) {
    const { installation, isAdmin, isPartner, isRelax } = this.props;
    const data = this.state.data;
    const validationResult = validateReport(
      data,
      false,
      isRelax ? FAKE_SERVICE_TRUE : FAKE_SERVICE_FALSE,
      isAdmin
    );
    if (!validationResult || validationResult.success) {
      return null;
    }
    if (!path) {
      return validationResult.errors;
    }
    return get(validationResult.errors, path);
  }

  isStepDone(step, index) {
    if (step.type === "impianto") {
      const errors = this.validateAndGetErrors() || {};
      return (
        errors.files ||
        get(errors, "reportInfo.contatori.error") ||
        get(errors, "reportInfo.formule") ||
        get(errors, "reportInfo.costanti") ||
        get(errors, "reportInfo.tipologiaDiConsumo")
      );
    }
    if (step.type === "contatore") {
      const contatori = this.getValue("reportInfo.contatori") || [];
      const contatore = contatori[step.contatoreIndex];
      if (!contatore) {
        return false;
      }
      return !this.validateAndGetErrors(
        "reportInfo.contatori." + step.contatoreIndex
      );
    }
    if (step.type === "letture") {
      const contatori = this.getValue("reportInfo.contatori") || [];
      for (let ci = 0; ci < contatori.length; ci++) {
        const contatore = contatori[ci];
        if (!contatore) {
          return false;
        }
        let x = !this.validateAndGetErrors(
          "reportInfo.contatori." + ci + ".letture"
        );
        if (!x) {
          return false;
        }
      }
      return true;
    }
    if (step.type === "accise") {
      const anno = this.getValue("anno");

      return (
        !this.validateAndGetErrors("reportInfo.accontiAnnoPrecedente") &&
        (anno - 1 > 2021
          ? !this.validateAndGetErrors(
              "reportInfo.creditoPregressoNonUtilizzato"
            )
          : true)
      );
    }
    if (step.type === "riepilogoQuadri") {
      return !this.validateAndGetErrors();
    }
    if (step.type === "elencoPropriFornitori") {
      return !this.validateAndGetErrors("reportInfo.quadroEF");
    }

    if (step.type === "end") {
      return !this.validateAndGetErrors();
    }
    return false;
  }

  stepHasError(step, index) {
    if (step.type === "impianto") {
      return (
        this.state.errors &&
        (this.state.errors.files ||
          get(this.state.errors, "reportInfo.contatori.error") ||
          get(this.state.errors, "reportInfo.formule") ||
          get(this.state.errors, "reportInfo.costanti") ||
          get(this.state.errors, "reportInfo.tipologiaDiConsumo"))
      );
    }
    if (step.type === "contatore") {
      return (
        this.state.errors &&
        get(this.state.errors, "reportInfo.contatori." + step.contatoreIndex)
      );
    }
    if (step.type === "letture") {
      if (!this.state.errors) {
        return false;
      }
      const contatori = this.getValue("reportInfo.contatori") || [];
      for (let ci = 0; ci < contatori.length; ci++) {
        let x = get(
          this.state.errors,
          "reportInfo.contatori." + ci + ".letture"
        );
        if (x) {
          return true;
        }
      }
      return false;
    }
    if (step.type === "accise") {
      const anno = this.getValue("anno");
      return (
        this.state.errors &&
        (get(this.state.errors, "reportInfo.accontiAnnoPrecedente") ||
          (anno - 1 > 2021
            ? get(this.state.errors, "reportInfo.creditoPregressoNonUtilizzato")
            : false))
      );
    }
    if (step.type === "elencoPropriFornitori") {
      return this.state.errors && get(this.state.errors, "reportInfo.quadroEF");
    }

    return false;
  }

  wizardStepsRenderer = ({
    currentStep,
    stepIndex,
    cantBack,
    isInFinalStep,
    backHandler,
    nextHandler,
  }) => {
    let i = 0;
    const steps = this.getWizardSteps();
    const stepsEls = steps.map((step, index) => {
      const isActive = currentStep === index + 1;
      let itemLinkClass = ["nav-item"];
      if (isActive) {
        itemLinkClass = [...itemLinkClass, "active"];
        i = 1;
      } else if (
        i === 0 ||
        this.state.isFinished ||
        this.isStepDone(step, index)
      ) {
        itemLinkClass = [...itemLinkClass, "done"];
      }

      if (this.stepHasError(step, index)) {
        itemLinkClass = [...itemLinkClass, "with-error"];
      }

      return (
        <li key={index} className={itemLinkClass.join(" ")}>
          <a className="nav-link" onClick={() => this.selectWizardStep(index)}>
            <h6>{step.label}</h6>
            <p className="m-0">{step.caption}</p>
          </a>
        </li>
      );
    });

    return <ul className="nav nav-tabs step-anchor">{stepsEls}</ul>;
  };

  renderWizardComponents = ({ currentStep }) => {
    const steps = this.getWizardSteps();
    return steps.map((step, index) => {
      if (currentStep === index + 1) {
        let content;
        switch (step.type) {
          case "impianto":
            content = this.renderStepImpianto();
            break;
          case "contatore":
            content = this.renderContatore(step.contatoreIndex);
            break;
          case "letture":
            content = this.renderStepLetture();
            break;
          case "accise":
            content = this.renderAccise();
            break;
          case "riepilogoQuadri":
            content = this.renderStepRiepilogoQuadro(step.quadro);
            break;
          case "elencoPropriFornitori":
            content = this.renderStepElencoPropriFornitori();
            break;
          case "end":
            content = this.renderStepFinale();
            break;
          default:
            content = <pre>{JSON.stringify(step)}</pre>;
        }
        return (
          <div key={index} className="selected-step-component-container">
            {content}
          </div>
        );
      }
      return false;
    });
  };

  wizardCustomActions = ({
    currentStep,
    stepIndex,
    cantBack,
    isInFinalStep,
    backHandler,
    nextHandler,
  }) => {
    const { onCancel, readOnly, isClienteFinaleWebsite } = this.props;
    const { errorMessage, errors } = this.state;

    let nextButton;

    if (isInFinalStep) {
      if (readOnly) {
        nextButton =
          (onCancel && (
            <Button
              key="2"
              color="secondary"
              onClick={this.onCancel}
              disabled={this.state.loading}
            >
              Ok
            </Button>
          )) ||
          null;
      } else {
        nextButton = (
          <React.Fragment>
            <Button
              color="primary"
              onClick={() => {
                this.saveAsBozza = false;
                nextHandler();
              }}
              disabled={this.state.loading || this.state.isFinished}
            >
              Salva rendicontazione
            </Button>

            {!isClienteFinaleWebsite && (
              <Button
                color="primary"
                onClick={() => {
                  this.saveAsBozza = true;
                  nextHandler();
                }}
                disabled={this.state.loading || this.state.isFinished}
              >
                Salva bozza
              </Button>
            )}
          </React.Fragment>
        );
      }
    } else {
      nextButton = (
        <Button
          color="secondary"
          onClick={nextHandler}
          disabled={this.state.loading}
        >
          Avanti
        </Button>
      );
    }

    const hasErrors = size(errors) > 0;

    return (
      <div
        key="toolbar"
        className="btn-toolbar sw-toolbar sw-toolbar-bottom justify-content-end"
      >
        {errorMessage && (
          <div
            key="error"
            className="invalid-feedback"
            style={{
              display: "block",
              fontSize: 15,
            }}
          >
            {errorMessage} {hasErrors && <br />}
            {hasErrors &&
              "Verifica gli errori nelle schede segnalate in rosso."}
          </div>
        )}
        <Button
          color="secondary"
          onClick={backHandler}
          disabled={this.state.loading || cantBack}
        >
          Indietro
        </Button>
        {nextButton}
      </div>
    );
  };

  showFooter() {
    if (this.props.dichiarazione) {
      return true;
    }
    return false;
  }

  renderBody() {
    if (this.props.dichiarazione) {
      // show only the impianto panel
      return (
        <div className="report-card-body">{this.renderStepImpianto()}</div>
      );
    }

    const steps = this.getWizardSteps();

    return (
      <div className="report-card-body">
        <Row>
          <Col md="12">
            <div className="sw-main sw-theme-default">
              <Loki
                ref={(el) => (this._loki = el)}
                steps={steps}
                renderComponents={this.renderWizardComponents}
                renderSteps={this.wizardStepsRenderer}
                renderActions={this.wizardCustomActions}
                onFinish={this.submit}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ReportCardSteps;
