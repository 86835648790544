import moment from "moment/moment";
import { Contatore } from "energix-types/src/Contatore";
import { Impianto } from "energix-types/src/Impianto";
import { Azienda } from "energix-types/src/Azienda";
import getScadenzaTaraturaContatori from "./getScadenzaTaraturaContatori";
import getScadenzaSPI from "./getScadenzaSPI";
import getScadenzaFuelMix from "./getScadenzaFuelMix";
import getScadenzaAntimafia from "./getScadenzaAntimafia";
import {
  getScadenzaAreraContributoFunzionamento,
  getScadenzaAreraUnbundling,
  getScadenzaIndagineAnnuale,
} from "./getScandezaArera";
import { TipoServizioAcquistato } from "energix-types/src/ServizioAcquistatoOld";
import { Moment } from "moment";

const NOTIFICA_SE_MENO_DI_X_GIORNI = 30;

export type Scadenza = {
  servizioType: Omit<TipoServizioAcquistato, "ad_1">;
  inScadenza: number;
  total?: number;
};

function isScadenzaNear(
  scadenza: string | Date | null,
  today: Moment = moment()
): boolean {
  const diff = moment(scadenza).diff(today, "days");
  return diff < NOTIFICA_SE_MENO_DI_X_GIORNI;
}

export function getScadenze(
  contatori?: Contatore[],
  impianti?: Impianto[],
  aziende?: Azienda[],
  today: Moment = moment()
): Scadenza[] {
  const isScadenzaNearToday = (scadenza: string | Date | null) =>
    isScadenzaNear(scadenza, today);
  return [
    {
      servizioType: "taratura_contatori",
      inScadenza:
        contatori
          ?.filter((contatore) => contatore.servizioTaraturaAbilitato)
          ?.map(getScadenzaTaraturaContatori)
          ?.filter(isScadenzaNearToday)?.length || 0,
      total: countServizioAbilitato(contatori, "servizioTaraturaAbilitato"),
    },
    {
      servizioType: "scadenza_spi",
      inScadenza:
        impianti
          ?.filter((impianto) => impianto.servizioSPIAbilitato)
          ?.map(getScadenzaSPI)
          ?.filter(isScadenzaNearToday)?.length || 0,
      total: countServizioAbilitato(impianti, "servizioSPIAbilitato"),
    },
    {
      servizioType: "scadenza_fuel_mix",
      inScadenza:
        aziende
          ?.filter((azienda) => azienda.servizioFuelMixAbilitato)
          ?.map((a) => getScadenzaFuelMix(a.ultimoTaraturaFuelMix, today))
          ?.filter(isScadenzaNearToday)?.length || 0,
      total: countServizioAbilitato(aziende, "servizioFuelMixAbilitato"),
    },
    {
      servizioType: "scadenza_arera_indagine",
      inScadenza:
        aziende
          ?.filter((azienda) => azienda.servizioAreraIndagineAbilitato)
          ?.map((azienda) => getScadenzaIndagineAnnuale(azienda, today))
          ?.filter(isScadenzaNearToday)?.length || 0,
      total: countServizioAbilitato(aziende, "servizioAreraIndagineAbilitato"),
    },
    {
      servizioType: "scadenza_arera_unbundling",
      inScadenza:
        aziende
          ?.filter((azienda) => azienda.servizioAreraUnbundlingAbilitato)
          ?.map(getScadenzaAreraUnbundling)
          ?.filter(isScadenzaNearToday)?.length || 0,
      total: countServizioAbilitato(
        aziende,
        "servizioAreraUnbundlingAbilitato"
      ),
    },
    {
      servizioType: "scadenza_arera_contributo_funzionamento",
      inScadenza:
        aziende
          ?.filter(
            (azienda) => azienda.servizioAreraContributoFunzionamentoAbilitato
          )
          ?.map((azienda) => getScadenzaAreraContributoFunzionamento(azienda))
          ?.filter(isScadenzaNearToday)?.length || 0,
      total: countServizioAbilitato(
        aziende,
        "servizioAreraContributoFunzionamentoAbilitato"
      ),
    },
    {
      servizioType: "scadenza_antimafia",
      inScadenza:
        aziende
          ?.filter((azienda) => azienda.servizioAntimafiaAbilitato)
          ?.map(getScadenzaAntimafia)
          ?.filter(isScadenzaNearToday)?.length || 0,
      total: countServizioAbilitato(aziende, "servizioAntimafiaAbilitato"),
    },
  ].filter(({ inScadenza }) => inScadenza > 0);
}

function countServizioAbilitato<T extends Azienda | Contatore | Impianto>(
  objects: T[] | undefined,
  abilitatoKey: keyof T
) {
  return objects?.filter((o) => o[abilitatoKey])?.length;
}
