import { Impianto } from "energix-types/src/Impianto";
import { Dichiarazione } from "energix-types/src/Dichiarazione";

export function getXmlToSignDownloadUrl(
  token: string,
  dichiarazione: Dichiarazione,
  impianto: Impianto
) {
  const fileBaseName = getFileToSignBaseName(impianto, dichiarazione);
  return `/api/dichiarazione/v2/download/xml/${token}/${dichiarazione.id}/${fileBaseName}.xml`;
}

export function getPdfDownloadUrl(
  token: string,
  dichiarazione: Dichiarazione,
  impianto: Impianto
) {
  const fileBaseName = getFileToSignBaseName(impianto, dichiarazione);
  return `/api/dichiarazione/v2/download/pdf/${token}/${dichiarazione.id}/${fileBaseName}.pdf`;
}

function getFileToSignBaseName(
  impianto: Impianto,
  dichiarazione: Dichiarazione
) {
  const anno = dichiarazione.anno - 1;
  const codiceDitta = impianto.installationCode;
  return `${codiceDitta}_${anno}`;
}
