import {
  installationsByType,
  tipologieDiCessione,
  tipologieDiRicezione,
} from "common/installations";
import { each, some, get, set, mapValues } from "lodash";
import { analyzeFormule } from "common/formule";
import codiceRegexp from "common/grammar/codiceRegexp";
import { getDateLettureRichieste } from "dogane/fileGenerator/lettureUtils";
import { formuleVariablesKeys } from "dogane/fileGenerator/calcoloPerUsoProprio";
// import {quardiUIDetails} from 'app/components/ReportCardQuadri'
import { quardiUIDetails } from "../../app/components/ReportCardQuadri";

function getMonth(date) {
  return parseInt(date.substring(5, 7), 10);
}

function validateNewReportForm(
  originalPayload,
  resetUnwantedFields,
  service,
  isAdmin
) {
  const errors = {};
  let isFormValid = true;
  let message = "";

  const payload = { ...originalPayload };

  // set(originalPayload, "reportInfo.statoReport", undefined)

  if (!payload) {
    isFormValid = false;
  } else if (!payload.anno) {
    isFormValid = false;
    errors.anno = "Anno non specificato";
  } else if (!payload.type || !installationsByType[payload.type]) {
    isFormValid = false;
    errors.type = payload.type
      ? "Tipologia sconosciuta"
      : "Seleziona una tipologia";
  } else if (!isAdmin && service && service.sendPdfOnly) {
    if (!payload.files || !payload.files.length) {
      isFormValid = false;
      errors.files = "Carica i file richiesti";
    }
  } else {
    const installationType = installationsByType[payload.type];

    if (payload.reportInfo && payload.reportInfo.datiQuadri) {
      // datiQuadri manuali
      each(quardiUIDetails, ({ sections }) => {
        each(sections, ({ dataInput, useMonths, onlyAnno }) => {
          if (onlyAnno && payload.anno - 1 !== onlyAnno) {
            return;
          }
          const { type, key, arrayKey, rowValueKey } = dataInput;
          const data = payload.reportInfo.datiQuadri[key];
          const dataArray = data && data[arrayKey];

          if (!dataArray) {
            return;
          }

          if (type === "table") {
            dataArray.forEach((row, rowIndex) => {
              if (!row) {
                return;
              }
              dataInput.columns.forEach((column) => {
                const v = row[column.key];
                const error = column.getError && column.getError(v);
                if (error) {
                  isFormValid = false;
                  set(
                    errors,
                    `reportInfo.datiQuadri.${key}.${arrayKey}.${rowIndex}.${column.key}`,
                    error
                  );
                }
              });
            });
          } else if (type === "tableMonth") {
            const _getKey = (value, mese) => {
              if (useMonths) {
                return `${mese}-${value}`;
              }
              return `${value}`;
            };
            const getKey = (row) => _getKey(row[rowValueKey], row.mese);
            const getKey2 = (dataInputRow, mese) =>
              _getKey(dataInputRow.value, mese);

            const rowsIndexByKey = {};
            dataArray.forEach((row, rowIndex) => {
              if (!row) {
                return;
              }
              const key = getKey(row);
              rowsIndexByKey[key] = rowIndex;
            });

            const nMesi = useMonths ? 12 : 1;
            for (let mese = 1; mese <= nMesi; mese++) {
              dataInput.rows.forEach((dataInputRow) => {
                if (dataInputRow.noValue) {
                  return;
                }
                const rowKey = getKey2(dataInputRow, mese);
                const rowIndex = rowsIndexByKey[rowKey];
                const row = dataArray[rowIndex];
                if (!row) {
                  return;
                }

                dataInput.columns.forEach((column) => {
                  const { filterColumn } = dataInputRow;
                  if (filterColumn && !filterColumn[column.key]) {
                    return;
                  }
                  if (filterColumn && filterColumn[column.key] === "readonly") {
                    return;
                  }

                  let v = row[column.key];
                  if (column.type === "auto") {
                    v = column.valueFunction(row);
                  }

                  const error = column.getError && column.getError(v);
                  if (error) {
                    isFormValid = false;
                    set(
                      errors,
                      `reportInfo.datiQuadri.${key}.${arrayKey}.${rowIndex}.${column.key}`,
                      error
                    );
                  }
                });
              });
            }
          }
        });
      });
    } else {
      const haCeduta = installationType.haCeduta;
      const letturaAnnuale = installationType.letturaAnnuale;
      const chiedeDatiAcciseAnnoPrecedente =
        installationType.chiedeDatiAcciseAnnoPrecedente;
      const chiediNumeroDiContatori = installationType.chiediNumeroDiContatori;

      const contatoriObjects = {};

      const minDate = payload.anno - 1 + "-01-01";
      const maxDate = payload.anno - 1 + "-12-31";

      const contatori = get(payload, "reportInfo.contatori") || [];

      //TODO: mettere che ci sia almeno un contatore, o uno di prod e uno ceduta, boh

      if (isAdmin !== "clientWebsite" && installationType.necessitaAlgoritmo) {
        const formule = get(payload, "reportInfo.formule") || [];

        if (!formule.length) {
          isFormValid = false;
          set(
            errors,
            "reportInfo.formule.errors.0",
            "Ci sono errori nella formula"
          );
        } else {
          const contatori = get(payload, "reportInfo.contatori");
          const costanti = get(payload, "reportInfo.costanti");

          if (costanti) {
            costanti.forEach((costante, index) => {
              const ok = costante.codice && codiceRegexp.test(costante.codice);
              if (!ok) {
                isFormValid = false;
                //don't set the errors value to the real key because the check is done already on the client side
                set(
                  errors,
                  "reportInfo.costanti." + index + ".codice_placeholder",
                  "Inserisci i dati"
                );
              }

              if (typeof costante.value !== "number") {
                isFormValid = false;
                set(
                  errors,
                  "reportInfo.costanti." + index + ".value",
                  "Inserisci il valore della costante"
                );
              }
            });
          }

          const context = {
            contatori: contatori,
            costanti: costanti,
          };

          formule.map((formulaObj, index) => {
            const result = analyzeFormule(payload.anno, formulaObj, context);
            each(formuleVariablesKeys, (formulaKey, formulaName) => {
              if (!result[formulaName].ok) {
                isFormValid = false;
                set(
                  errors,
                  "reportInfo.formule." + index + "." + formulaKey,
                  "Ci sono errori nella formula"
                );
              }
            });
            if (
              typeof formulaObj.formulaNonSottoposti === "string" &&
              !get(payload, "reportInfo.tipologiaDiConsumo")
            ) {
              isFormValid = false;
              set(
                errors,
                "reportInfo.tipologiaDiConsumo",
                "Specificare la tipologia di consumo"
              );
            }
          });
        }
      } else {
        var hasProduzione = some(contatori, (c) => c.type === "produzione");
        if (installationType.haCeduta) {
          var hasCeduta = some(contatori, (c) => c.type === "ceduta");
          if (!hasCeduta || !hasProduzione) {
            isFormValid = false;
            set(
              errors,
              "reportInfo.contatori.error",
              "È necessario almeno un contatore di produzione e almeno uno di energia ceduta"
            );
          }
        } else {
          if (!hasProduzione) {
            isFormValid = false;
            set(
              errors,
              "reportInfo.contatori.error",
              "È necessario almeno un contatore di produzione"
            );
          }
        }
      }

      contatori.forEach((contatore, index) => {
        // check stato contatori
        // if(contatore.statoLetture) {
        //   set(originalPayload, "reportInfo.statoReport", 'bozza')
        // }

        const baseKey = "reportInfo.contatori." + index;
        const obj = {
          key: baseKey,
          // solo per il contatore (non per quello cambiato...)
          tipologiaDiCessione:
            installationType.tipologiaDiCessione &&
            installationType.tipologiaDiCessione(
              contatore.type,
              payload.reportInfo
            ),
          tipologiaDiRicezione:
            installationType.tipologiaDiRicezione &&
            installationType.tipologiaDiRicezione(contatore.type),
        };
        contatoriObjects[obj.key] = obj;

        let startDate = get(payload, baseKey + ".startDate");
        let endDate = get(payload, baseKey + ".endDate");
        if (startDate) {
          if (startDate < minDate || startDate > maxDate) {
            // isFormValid = false;
            // set(errors, baseKey + '.startDate', 'La data deve essere nell\'anno ' + (payload.anno - 1))
          } else {
            obj.from = startDate;
          }
        }
        if (endDate) {
          if (endDate < minDate || endDate > maxDate) {
            // isFormValid = false;
            // set(errors, baseKey + '.endDate', 'La data deve essere nell\'anno ' + (payload.anno - 1))
          } else {
            obj.to = endDate;
          }
        }
      });

      each(
        contatoriObjects,
        ({ key, from, to, tipologiaDiCessione, tipologiaDiRicezione }) => {
          const contatore = get(payload, key);

          if (!contatore) {
            isFormValid = false;
            set(errors, key + ".matricola", "Inserisci i dati");
            return;
          }

          const toCheck = {
            matricola: { error: "Inserisci la matricola" },
            k: { error: "Inserisci la costante" },
          };

          if (installationType.necessitaAlgoritmo) {
            const ok = contatore.codice && codiceRegexp.test(contatore.codice);
            if (!ok) {
              isFormValid = false;
              //don't set the errors value to the real key because the check is done already on the client side
              set(errors, key + ".codice_placeholder", "Inserisci i dati");
            }
          }

          if (tipologiaDiCessione) {
            (toCheck.tipologiaDiCessione = {
              error: "Scegli la tipologia di cessione",
              values: tipologieDiCessione,
            }),
              (toCheck.codiceIdentificativoOfficinaDestinataria = {
                error: "Inserisci il codice identificativo",
              });
          }

          if (tipologiaDiRicezione) {
            toCheck.tipologiaDiRicezione = {
              error: "Scegli la tipologia di ricezione",
              values: tipologieDiRicezione,
            };
            toCheck.codiceIdentificativoOfficinaFornitrice = {
              error: "Inserisci il codice identificativo",
            };
          }

          each(toCheck, ({ error, values }, k) => {
            var value = get(payload, key + "." + k);
            if (!value) {
              isFormValid = false;
              set(errors, key + "." + k, error);
            } else if (values && !values[value]) {
              isFormValid = false;
              set(errors, key + "." + k, error);
            }
          });

          let meseFrom = from ? getMonth(from) : null;
          let meseTo = to ? getMonth(to) : null;

          // letture
          let checkNumeroCifre;
          let checkLetturaMaggiorePrecedente;
          if (contatore.numeroCifreIntere) {
            const azzerato = contatore.azzerato;
            const maxValue = Math.pow(10, contatore.numeroCifreIntere);
            checkNumeroCifre = (value) => value < maxValue;
            if (azzerato) {
              checkLetturaMaggiorePrecedente = () => true;
            } else {
              checkLetturaMaggiorePrecedente = (value, peviousValue) =>
                peviousValue <= value;
            }
          } else {
            checkNumeroCifre = (value) => true;
            checkLetturaMaggiorePrecedente = (value, peviousValue) =>
              peviousValue <= value;
          }

          var primoValore = true;
          var lastValue = null;
          var erroreNeiValori = false;

          // se contatore.statoLetture !== undefined vuol dire che è una bozza, ignora il controllo dei dati
          if (!get(originalPayload, "reportInfo.statoReport")) {
            //     !contatore.statoLetture){

            const formuleOrNull = get(payload, "reportInfo.formule") || null;

            const lettureRichieste = getDateLettureRichieste(
              payload.anno - 1,
              contatore.startDate,
              contatore.endDate,
              letturaAnnuale,
              payload,
              formuleOrNull
            );

            const kToKeep = {};

            lettureRichieste.forEach(({ key: dateKey }, index) => {
              if (contatore.inserimentoDirettoKwh && index === 0) {
                // la prima letture è sempre la fine del mese prima o cmq l'inizio
                // ma quando differenzaLetture si accorge che stiamo facendo inserimentoDirettoKwh
                // non fa effettivamente la differenza, ma usa la letture finale del mese che è quello
                // che inserirsce l'utente come "kwh"
                // TLDR: non serve la prima lettura!!
                return;
              }

              const k = key + ".letture." + dateKey;
              kToKeep[k] = true;

              const value = get(payload, k);
              if (typeof value !== "number" || !(value >= 0)) {
                isFormValid = false;
                if (contatore.inserimentoDirettoKwh) {
                  set(errors, k, "Inserisci i kWh");
                } else {
                  set(errors, k, "Inserisci la lettura");
                }
                erroreNeiValori = true;
              } else if (!contatore.inserimentoDirettoKwh) {
                if (!checkNumeroCifre(value)) {
                  isFormValid = false;
                  set(errors, k, "Controlla il numero di cifre");
                  erroreNeiValori = true;
                } else if (!erroreNeiValori) {
                  if (primoValore) {
                    lastValue = value;
                    primoValore = false;
                  } else {
                    if (!checkLetturaMaggiorePrecedente(value, lastValue)) {
                      isFormValid = false;
                      set(errors, k, "Le letture devono essere crescenti");
                      erroreNeiValori = true;
                    }
                    lastValue = value;
                  }
                }
              }
            });

            if (resetUnwantedFields) {
              const allKeys = Object.keys(get(payload, key + ".letture") || {});
              allKeys.forEach((dateKey) => {
                const k = key + ".letture." + dateKey;
                if (!kToKeep[k]) {
                  set(originalPayload, k, undefined);
                }
              });
            }
          }
        }
      );

      // accise anno precedente
      if (!get(originalPayload, "reportInfo.statoReport")) {
        if (chiedeDatiAcciseAnnoPrecedente) {
          for (var month = 1; month <= 12; month++) {
            if (
              typeof get(
                payload,
                "reportInfo.accontiAnnoPrecedente." + month
              ) !== "number"
            ) {
              isFormValid = false;
              set(
                errors,
                "reportInfo.accontiAnnoPrecedente." + month,
                "Inserisci il valore richiesto"
              );
            }
          }

          if (payload.anno - 1 > 2021) {
            if (
              typeof get(
                payload,
                "reportInfo.creditoPregressoNonUtilizzato"
              ) !== "number"
            ) {
              isFormValid = false;
              set(
                errors,
                "reportInfo.creditoPregressoNonUtilizzato",
                "Inserisci il valore richiesto"
              );
            }
          }
        } else if (resetUnwantedFields) {
          set(originalPayload, "reportInfo.accontiAnnoPrecedente", undefined);
          set(
            originalPayload,
            "reportInfo.creditoPregressoNonUtilizzato",
            undefined
          );
        }
      }
    }

    // 13. ALLEGATO – ELENCO PROPRI FORNITORI E CEDENTI
    const anno = payload.anno;
    if (installationType.chiediQuadroEF && anno - 1 >= 2023) {
      const necessario = get(originalPayload, "reportInfo.quadroEF.necessario");
      if (typeof necessario !== "boolean") {
        isFormValid = false;
        set(
          errors,
          "reportInfo.quadroEF.necessario",
          "Selezionare una opzione"
        );
      } else if (necessario) {
        const fornitori =
          get(originalPayload, "reportInfo.quadroEF.fornitori") || [];

        fornitori.forEach((fornitore, index) => {
          if (
            !fornitore.provenienza ||
            !/^[ABC]$/.test(fornitore.provenienza)
          ) {
            isFormValid = false;
            set(
              errors,
              `reportInfo.quadroEF.fornitori.${index}.provenienza`,
              "Seleziona un valore"
            );
          }
          if (!fornitore.codice) {
            isFormValid = false;
            set(
              errors,
              `reportInfo.quadroEF.fornitori.${index}.codice`,
              "Inserisci l'identificativo"
            );
          }
          if (typeof fornitore.kwh !== "number") {
            isFormValid = false;
            set(
              errors,
              `reportInfo.quadroEF.fornitori.${index}.kwh`,
              "Inserisci il valore"
            );
          }
        });
      }
    }
  }

  if (!isFormValid) {
    message = "Ci sono errori nei dati inseriti.";
  }

  if (get(originalPayload, "reportInfo.statoReport")) {
    //IGNORA ERRORI PER BOZZA
    return {
      success: true,
      message: "",
      errors: {},
    };
  }

  return {
    success: isFormValid,
    message,
    errors,
  };
}

export default validateNewReportForm;
