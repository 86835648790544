import numeral from 'numeral'

function _replaceSelectedVal(input, text) {
  if ('selectionStart' in input) {
    var start = input.selectionStart + 1;
    input.value = input.value.substr(0, input.selectionStart) +
      text +
      input.value
      .substr(input.selectionEnd, input.value.length);
    input.selectionStart = start;
    input.selectionEnd = start;
    input.focus();
  } else if (document.selection) {
    input.focus();
    var sel = document.selection.createRange();
    sel.text = text;
    // Move selection start and end to 0 position
    sel.moveStart('character', -input.value.length);

    // Move selection start and end to desired position
    sel.moveStart('character', sel.text.length);
    sel.moveEnd('character', 0);
    sel.select();
  } else {
    input.value += text;
  }
}


var keydownCode = '';
var Opera = (navigator && navigator.userAgent && navigator.userAgent.match(/Opera|OPR\//)) ? true : false;
document.addEventListener('keydown', function (event) {
  const el = event.target;
  if (el.type !== 'text') {
    return; // this only works in text input field (and some others that I don't care about)
  }
  const tag = el.tagName && el.tagName.toLowerCase();
  if(tag === 'input' || tag === 'textarea'){
    keydownCode = event.keyCode;

  }
});
document.addEventListener('keypress', function (event) {
  try{
    const el = event.target;
    if (el.type !== 'text') {
      return; // this only works in text input field (and some others that I don't care about)
    }
    const tag = el.tagName && el.tagName.toLowerCase();
    if(tag === 'input' || tag === 'textarea'){
      var numericPadPeriodPressed = Opera ? 78 == keydownCode : 110 == keydownCode;
      if (numericPadPeriodPressed &&
        !event.shiftKey && !event.ctrlKey &&
        !event.altKey) {
        var languageData = numeral.localeData();
        var decimalSeparator = languageData.delimiters.decimal;
        if(event.key !== decimalSeparator){
          _replaceSelectedVal(el, decimalSeparator);
          event.preventDefault();
        }
      }
    }
  }catch(err){
    console.log(err)
  }
});

