import { CALL_API } from "../middleware/api";

export const SERVICE_CANCEL_MULTI_REQUEST = "SERVICE_CANCEL_MULTI_REQUEST";
export const SERVICE_CANCEL_MULTI_SUCCESS = "SERVICE_CANCEL_MULTI_SUCCESS";
export const SERVICE_CANCEL_MULTI_FAILURE = "SERVICE_CANCEL_MULTI_FAILURE";

export const SERVICE_LICENSE_CREATE_REQUEST = "SERVICE_LICENSE_CREATE_REQUEST";
export const SERVICE_LICENSE_CREATE_SUCCESS = "SERVICE_LICENSE_CREATE_SUCCESS";
export const SERVICE_LICENSE_CREATE_FAILURE = "SERVICE_LICENSE_CREATE_FAILURE";

export const SERVICE_LICENSE_UPDATE_REQUEST = "SERVICE_LICENSE_UPDATE_REQUEST";
export const SERVICE_LICENSE_UPDATE_SUCCESS = "SERVICE_LICENSE_UPDATE_SUCCESS";
export const SERVICE_LICENSE_UPDATE_FAILURE = "SERVICE_LICENSE_UPDATE_FAILURE";

export const SERVICE_LICENSE_DELETE_REQUEST = "SERVICE_LICENSE_DELETE_REQUEST";
export const SERVICE_LICENSE_DELETE_SUCCESS = "SERVICE_LICENSE_DELETE_SUCCESS";
export const SERVICE_LICENSE_DELETE_FAILURE = "SERVICE_LICENSE_DELETE_FAILURE";

export const INSTALLATION_CREATE_REQUEST = "INSTALLATION_CREATE_REQUEST";
export const INSTALLATION_CREATE_SUCCESS = "INSTALLATION_CREATE_SUCCESS";
export const INSTALLATION_CREATE_FAILURE = "INSTALLATION_CREATE_FAILURE";

export const INSTALLATION_UPDATE_REQUEST = "INSTALLATION_UPDATE_REQUEST";
export const INSTALLATION_UPDATE_SUCCESS = "INSTALLATION_UPDATE_SUCCESS";
export const INSTALLATION_UPDATE_FAILURE = "INSTALLATION_UPDATE_FAILURE";

export const INSTALLATION_UPDATE_SPI_REQUEST =
  "INSTALLATION_UPDATE_SPI_REQUEST";
export const INSTALLATION_UPDATE_SPI_SUCCESS =
  "INSTALLATION_UPDATE_SPI_SUCCESS";
export const INSTALLATION_UPDATE_SPI_FAILURE =
  "INSTALLATION_UPDATE_SPI_FAILURE";

export const INSTALLATION_UPDATE_NOTE_REQUEST =
  "INSTALLATION_UPDATE_NOTE_REQUEST";
export const INSTALLATION_UPDATE_NOTE_SUCCESS =
  "INSTALLATION_UPDATE_NOTE_SUCCESS";
export const INSTALLATION_UPDATE_NOTE_FAILURE =
  "INSTALLATION_UPDATE_NOTE_FAILURE";

export const INSTALLATION_DELETE_REQUEST = "INSTALLATION_DELETE_REQUEST";
export const INSTALLATION_DELETE_SUCCESS = "INSTALLATION_DELETE_SUCCESS";
export const INSTALLATION_DELETE_FAILURE = "INSTALLATION_DELETE_FAILURE";

export const REPORT_DELETE_REQUEST = "REPORT_DELETE_REQUEST";
export const REPORT_DELETE_SUCCESS = "REPORT_DELETE_SUCCESS";
export const REPORT_DELETE_FAILURE = "REPORT_DELETE_FAILURE";

export const CONTATORE_UPDATE_REQUEST = "CONTATORE_UPDATE_REQUEST";
export const CONTATORE_UPDATE_SUCCESS = "CONTATORE_UPDATE_SUCCESS";
export const CONTATORE_UPDATE_FAILURE = "CONTATORE_UPDATE_FAILURE";

export const CONTATORE_UPDATE_TARATURA_REQUEST =
  "CONTATORE_UPDATE_TARATURA_REQUEST";
export const CONTATORE_UPDATE_TARATURA_SUCCESS =
  "CONTATORE_UPDATE_TARATURA_SUCCESS";
export const CONTATORE_UPDATE_TARATURA_FAILURE =
  "CONTATORE_UPDATE_TARATURA_FAILURE";

export const INSTALLATION_UPDATE_STATE_REQUEST =
  "INSTALLATION_UPDATE_STATE_REQUEST";
export const INSTALLATION_UPDATE_STATE_SUCCESS =
  "INSTALLATION_UPDATE_STATE_SUCCESS";
export const INSTALLATION_UPDATE_STATE_FAILURE =
  "INSTALLATION_UPDATE_STATE_FAILURE";

export const REPORT_CREATE_REQUEST = "REPORT_CREATE_REQUEST";
export const REPORT_CREATE_SUCCESS = "REPORT_CREATE_SUCCESS";
export const REPORT_CREATE_FAILURE = "REPORT_CREATE_FAILURE";

export const DICHIARAZIONE_DUPLICA_REQUEST = "DICHIARAZIONE_DUPLICA_REQUEST";
export const DICHIARAZIONE_DUPLICA_SUCCESS = "DICHIARAZIONE_DUPLICA_SUCCESS";
export const DICHIARAZIONE_DUPLICA_FAILURE = "DICHIARAZIONE_DUPLICA_FAILURE";

export const REPORT_UPDATE_REQUEST = "REPORT_UPDATE_REQUEST";
export const REPORT_UPDATE_SUCCESS = "REPORT_UPDATE_SUCCESS";
export const REPORT_UPDATE_FAILURE = "REPORT_UPDATE_FAILURE";

export const REPORT_UPDATE_REQUEST_SetSignedXml =
  "REPORT_UPDATE_REQUEST_SetSignedXml";
export const REPORT_UPDATE_SUCCESS_SetSignedXml =
  "REPORT_UPDATE_SUCCESS_SetSignedXml";
export const REPORT_UPDATE_FAILURE_SetSignedXml =
  "REPORT_UPDATE_FAILURE_SetSignedXml";

export const REPORT_SIGN_REQUEST = "REPORT_SIGN_REQUEST";
export const REPORT_SIGN_SUCCESS = "REPORT_SIGN_SUCCESS";
export const REPORT_SIGN_FAILURE = "REPORT_SIGN_FAILURE";

export const UPDATE_ACCOUNT_REQUEST = "UPDATE_ACCOUNT_REQUEST";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAILURE = "UPDATE_ACCOUNT_FAILURE";

export const PAYPAL_PAYMENT_REQUEST = "PAYPAL_PAYMENT_REQUEST";
export const PAYPAL_PAYMENT_SUCCESS = "PAYPAL_PAYMENT_SUCCESS";
export const PAYPAL_PAYMENT_FAILURE = "PAYPAL_PAYMENT_FAILURE";

export const CONFIRM_PAYMENT_REQUEST = "CONFIRM_PAYMENT_REQUEST";
export const CONFIRM_PAYMENT_SUCCESS = "CONFIRM_PAYMENT_SUCCESS";
export const CONFIRM_PAYMENT_FAILURE = "CONFIRM_PAYMENT_FAILURE";

export const CREATE_PARTNER_REQUEST = "CREATE_PARTNER_REQUEST";
export const CREATE_PARTNER_SUCCESS = "CREATE_PARTNER_SUCCESS";
export const CREATE_PARTNER_FAILURE = "CREATE_PARTNER_FAILURE";

export const EXCEL_FILE_IMPORT_TEST_REQUEST = "EXCEL_FILE_IMPORT_TEST_REQUEST";
export const EXCEL_FILE_IMPORT_TEST_SUCCESS = "EXCEL_FILE_IMPORT_TEST_SUCCESS";
export const EXCEL_FILE_IMPORT_TEST_FAILURE = "EXCEL_FILE_IMPORT_TEST_FAILURE";
export const EXCEL_FILE_IMPORT_REQUEST = "EXCEL_FILE_IMPORT_REQUEST";
export const EXCEL_FILE_IMPORT_SUCCESS = "EXCEL_FILE_IMPORT_SUCCESS";
export const EXCEL_FILE_IMPORT_FAILURE = "EXCEL_FILE_IMPORT_FAILURE";

export const EXCEL_FILE_IMPORT_LETTURE_TEST_REQUEST =
  "EXCEL_FILE_IMPORT_LETTURE_TEST_REQUEST";
export const EXCEL_FILE_IMPORT_LETTURE_TEST_SUCCESS =
  "EXCEL_FILE_IMPORT_LETTURE_TEST_SUCCESS";
export const EXCEL_FILE_IMPORT_LETTURE_TEST_FAILURE =
  "EXCEL_FILE_IMPORT_LETTURE_TEST_FAILURE";
export const EXCEL_FILE_IMPORT_LETTURE_REQUEST =
  "EXCEL_FILE_IMPORT_LETTURE_REQUEST";
export const EXCEL_FILE_IMPORT_LETTURE_SUCCESS =
  "EXCEL_FILE_IMPORT_LETTURE_SUCCESS";
export const EXCEL_FILE_IMPORT_LETTURE_FAILURE =
  "EXCEL_FILE_IMPORT_LETTURE_FAILURE";

export const DICHIARAZIONE_FILE_IMPORT_TEST_REQUEST =
  "DICHIARAZIONE_FILE_IMPORT_TEST_REQUEST";
export const DICHIARAZIONE_FILE_IMPORT_TEST_SUCCESS =
  "DICHIARAZIONE_FILE_IMPORT_TEST_SUCCESS";
export const DICHIARAZIONE_FILE_IMPORT_TEST_FAILURE =
  "DICHIARAZIONE_FILE_IMPORT_TEST_FAILURE";
export const DICHIARAZIONE_FILE_IMPORT_REQUEST =
  "DICHIARAZIONE_FILE_IMPORT_REQUEST";
export const DICHIARAZIONE_FILE_IMPORT_SUCCESS =
  "DICHIARAZIONE_FILE_IMPORT_SUCCESS";
export const DICHIARAZIONE_FILE_IMPORT_FAILURE =
  "DICHIARAZIONE_FILE_IMPORT_FAILURE";

export const VENDITORE_FILE_IMPORT_TEST_REQUEST =
  "VENDITORE_FILE_IMPORT_TEST_REQUEST";
export const VENDITORE_FILE_IMPORT_TEST_SUCCESS =
  "VENDITORE_FILE_IMPORT_TEST_SUCCESS";
export const VENDITORE_FILE_IMPORT_TEST_FAILURE =
  "VENDITORE_FILE_IMPORT_TEST_FAILURE";
export const VENDITORE_FILE_IMPORT_REQUEST = "VENDITORE_FILE_IMPORT_REQUEST";
export const VENDITORE_FILE_IMPORT_SUCCESS = "VENDITORE_FILE_IMPORT_SUCCESS";
export const VENDITORE_FILE_IMPORT_FAILURE = "VENDITORE_FILE_IMPORT_FAILURE";

export const updatePartnerNumeroDiImpianti_REQUEST =
  "updatePartnerNumeroDiImpianti_REQUEST";
export const updatePartnerNumeroDiImpianti_SUCCESS =
  "updatePartnerNumeroDiImpianti_SUCCESS";
export const updatePartnerNumeroDiImpianti_FAILURE =
  "updatePartnerNumeroDiImpianti_FAILURE";

export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const GET_FILE_INFO_REQUEST = "GET_FILE_INFO_REQUEST";
export const GET_FILE_INFO_SUCCESS = "GET_FILE_INFO_SUCCESS";
export const GET_FILE_INFO_FAILURE = "GET_FILE_INFO_FAILURE";

export const SEND_REPORT_REQUEST = "SEND_REPORT_REQUEST";
export const SEND_REPORT_SUCCESS = "SEND_REPORT_SUCCESS";
export const SEND_REPORT_FAILURE = "SEND_REPORT_FAILURE";

export const REPORT_generaClientWebsiteLink_REQUEST =
  "REPORT_generaClientWebsiteLink_REQUEST";
export const REPORT_generaClientWebsiteLink_SUCCESS =
  "REPORT_generaClientWebsiteLink_SUCCESS";
export const REPORT_generaClientWebsiteLink_FAILURE =
  "REPORT_generaClientWebsiteLink_FAILURE";

export const REPORT_sendClientWebsiteLettureLinkByEmail_REQUEST =
  "REPORT_sendClientWebsiteLettureLinkByEmail_REQUEST";
export const REPORT_sendClientWebsiteLettureLinkByEmail_SUCCESS =
  "REPORT_sendClientWebsiteLettureLinkByEmail_SUCCESS";
export const REPORT_sendClientWebsiteLettureLinkByEmail_FAILURE =
  "REPORT_sendClientWebsiteLettureLinkByEmail_FAILURE";

export const REPORT_sendClientWebsiteFirmaXmlLinkByEmail_REQUEST =
  "REPORT_sendClientWebsiteFirmaXmlLinkByEmail_REQUEST";
export const REPORT_sendClientWebsiteFirmaXmlLinkByEmail_SUCCESS =
  "REPORT_sendClientWebsiteFirmaXmlLinkByEmail_SUCCESS";
export const REPORT_sendClientWebsiteFirmaXmlLinkByEmail_FAILURE =
  "REPORT_sendClientWebsiteFirmaXmlLinkByEmail_FAILURE";

export const SIMPLE_FETCH_REQUEST = "SIMPLE_FETCH_REQUEST";
export const SIMPLE_FETCH_SUCCESS = "SIMPLE_FETCH_SUCCESS";
export const SIMPLE_FETCH_FAILURE = "SIMPLE_FETCH_FAILURE";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export const simpleFetch = (query) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [SIMPLE_FETCH_REQUEST, SIMPLE_FETCH_SUCCESS, SIMPLE_FETCH_FAILURE],
      endpoint: typeof query === "string" ? query : query.endpoint,
      method: query.method,
      body: query.body,
    },
  });
};

export const createNewServiceLicense = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        SERVICE_LICENSE_CREATE_REQUEST,
        SERVICE_LICENSE_CREATE_SUCCESS,
        SERVICE_LICENSE_CREATE_FAILURE,
      ],
      endpoint: "/api/serviceLicense/create",
      method: "POST",
      body: params,
    },
  });
};

export const createNewInstallation = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        INSTALLATION_CREATE_REQUEST,
        INSTALLATION_CREATE_SUCCESS,
        INSTALLATION_CREATE_FAILURE,
      ],
      endpoint: "/api/installation/create",
      method: "POST",
      body: params,
    },
  });
};

export const updateInstallation = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        INSTALLATION_UPDATE_REQUEST,
        INSTALLATION_UPDATE_SUCCESS,
        INSTALLATION_UPDATE_FAILURE,
      ],
      endpoint: "/api/installation/update",
      method: "POST",
      body: params,
    },
  });
};

export const updateInstallationSPI = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        INSTALLATION_UPDATE_SPI_REQUEST,
        INSTALLATION_UPDATE_SPI_SUCCESS,
        INSTALLATION_UPDATE_SPI_FAILURE,
      ],
      endpoint: "/api/installation/updateSPI",
      method: "POST",
      body: params,
    },
  });
};

export const updateInstallationNote = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        INSTALLATION_UPDATE_NOTE_REQUEST,
        INSTALLATION_UPDATE_NOTE_SUCCESS,
        INSTALLATION_UPDATE_NOTE_FAILURE,
      ],
      endpoint: "/api/installation/note",
      method: "POST",
      body: params,
    },
  });
};

export const deleteInstallation = (id) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        INSTALLATION_DELETE_REQUEST,
        INSTALLATION_DELETE_SUCCESS,
        INSTALLATION_DELETE_FAILURE,
      ],
      endpoint: "/api/installation/" + id + "/delete",
      method: "POST",
      body: {},
    },
  });
};

export const deleteReport = (id) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        REPORT_DELETE_REQUEST,
        REPORT_DELETE_SUCCESS,
        REPORT_DELETE_FAILURE,
      ],
      endpoint: "/api/report/" + id + "/delete",
      method: "POST",
      body: {},
    },
  });
};

export const updateInstallationState =
  (installation, newState) => (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [
          INSTALLATION_UPDATE_STATE_REQUEST,
          INSTALLATION_UPDATE_STATE_SUCCESS,
          INSTALLATION_UPDATE_STATE_FAILURE,
        ],
        endpoint: "/api/installation/update/installationState",
        method: "POST",
        body: {
          installationId: installation.id,
          installationState: newState,
        },
      },
    });
  };

export const updateContatore = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        CONTATORE_UPDATE_REQUEST,
        CONTATORE_UPDATE_SUCCESS,
        CONTATORE_UPDATE_FAILURE,
      ],
      endpoint: "/api/contatori/update",
      method: "POST",
      body: params,
    },
  });
};

export const updateContatoreTaratura = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        CONTATORE_UPDATE_TARATURA_REQUEST,
        CONTATORE_UPDATE_TARATURA_SUCCESS,
        CONTATORE_UPDATE_TARATURA_FAILURE,
      ],
      endpoint: "/api/contatori/updateTaratura",
      method: "POST",
      body: params,
    },
  });
};

export const createNewReport = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        REPORT_CREATE_REQUEST,
        REPORT_CREATE_SUCCESS,
        REPORT_CREATE_FAILURE,
      ],
      endpoint: "/api/report/create",
      method: "POST",
      body: params,
    },
  });
};

export const duplicaDichiarazione = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        DICHIARAZIONE_DUPLICA_REQUEST,
        DICHIARAZIONE_DUPLICA_SUCCESS,
        DICHIARAZIONE_DUPLICA_FAILURE,
      ],
      endpoint: "/api/report/duplica",
      method: "POST",
      body: params,
    },
  });
};

export const updateReport = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        REPORT_UPDATE_REQUEST,
        REPORT_UPDATE_SUCCESS,
        REPORT_UPDATE_FAILURE,
      ],
      endpoint: "/api/report/update",
      method: "POST",
      body: params,
    },
  });
};

export const saveReportSetSignedXml = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        REPORT_UPDATE_REQUEST_SetSignedXml,
        REPORT_UPDATE_SUCCESS_SetSignedXml,
        REPORT_UPDATE_FAILURE_SetSignedXml,
      ],
      endpoint: "/api/report/update/setSignedXml",
      method: "POST",
      body: params,
    },
  });
};

const getAccontiReport_REQUEST = "getAccontiReport_REQUEST";
const getAccontiReport_SUCCESS = "getAccontiReport_SUCCESS";
const getAccontiReport_FAILURE = "getAccontiReport_FAILURE";

export const getAccontiReport = (reportId) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        getAccontiReport_REQUEST,
        getAccontiReport_SUCCESS,
        getAccontiReport_FAILURE,
      ],
      endpoint: "/api/report/" + reportId + "/acconti",
      method: "GET",
    },
  });
};

const getAccontiExcelFileContent_REQUEST = "getAccontiExcelFileContent_REQUEST";
const getAccontiExcelFileContent_SUCCESS = "getAccontiExcelFileContent_SUCCESS";
const getAccontiExcelFileContent_FAILURE = "getAccontiExcelFileContent_FAILURE";

export const getAccontiExcelFileContent = (reportId) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        getAccontiExcelFileContent_REQUEST,
        getAccontiExcelFileContent_SUCCESS,
        getAccontiExcelFileContent_FAILURE,
      ],
      endpoint: "/api/report/" + reportId + "/accontiXlsContent",
      method: "GET",
    },
  });
};

export const signReport = (report, params) => async (dispatch) => {
  params = {
    ...params,
  };
  if (params.fileP12 && !params.fileP12.fileP12KeystoreId) {
    let data = await getBase64(params.fileP12);
    const index = data.indexOf("base64,");
    data = data.substring(index + 7);
    params.fileP12 = data;
  }
  return dispatch({
    [CALL_API]: {
      types: [REPORT_SIGN_REQUEST, REPORT_SIGN_SUCCESS, REPORT_SIGN_FAILURE],
      endpoint: "/api/report/" + report.id + "/sign",
      method: "POST",
      body: params,
    },
  });
};

export const uploadFile = (file) => (dispatch) => {
  var data = new FormData();
  data.append("file", file);
  return dispatch({
    [CALL_API]: {
      types: [UPLOAD_FILE_REQUEST, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE],
      endpoint: "/api/uploadFiles",
      method: "POST",
      body: data,
    },
  });
};

export const getFileInfo = (fileId) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        GET_FILE_INFO_REQUEST,
        GET_FILE_INFO_SUCCESS,
        GET_FILE_INFO_FAILURE,
      ],
      endpoint: "/file/" + fileId + "/info",
      method: "GET",
    },
  });
};

export const updateAccount = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        UPDATE_ACCOUNT_REQUEST,
        UPDATE_ACCOUNT_SUCCESS,
        UPDATE_ACCOUNT_FAILURE,
      ],
      endpoint: "/api/account/update",
      method: "POST",
      body: params,
    },
  });
};

export const updateServiceLicense = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        SERVICE_LICENSE_UPDATE_REQUEST,
        SERVICE_LICENSE_UPDATE_SUCCESS,
        SERVICE_LICENSE_UPDATE_FAILURE,
      ],
      endpoint: "/api/serviceLicense/update",
      method: "POST",
      body: params,
    },
  });
};

export const deleteServiceLicense = (id) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        SERVICE_LICENSE_DELETE_REQUEST,
        SERVICE_LICENSE_DELETE_SUCCESS,
        SERVICE_LICENSE_DELETE_FAILURE,
      ],
      endpoint: "/api/serviceLicense/" + id + "/delete",
      method: "POST",
      body: {},
    },
  });
};

export const startPaypalPayment = (serviceIds) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        PAYPAL_PAYMENT_REQUEST,
        PAYPAL_PAYMENT_SUCCESS,
        PAYPAL_PAYMENT_FAILURE,
      ],
      endpoint: "/api/paypal/init",
      method: "POST",
      body: {
        serviceIds: serviceIds,
      },
    },
  });
};

export const confirmPayment = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        CONFIRM_PAYMENT_REQUEST,
        CONFIRM_PAYMENT_SUCCESS,
        CONFIRM_PAYMENT_FAILURE,
      ],
      endpoint: "/api/admin/payment/confirm",
      method: "POST",
      body: params,
    },
  });
};

export const insertPartner = (params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        CREATE_PARTNER_REQUEST,
        CREATE_PARTNER_SUCCESS,
        CREATE_PARTNER_FAILURE,
      ],
      endpoint: "/api/account/createPartner",
      method: "POST",
      body: params,
    },
  });
};

export const excelFileImportTest = (file) => (dispatch) => {
  var data = new FormData();
  data.append("file", file);
  return dispatch({
    [CALL_API]: {
      types: [
        EXCEL_FILE_IMPORT_TEST_REQUEST,
        EXCEL_FILE_IMPORT_TEST_SUCCESS,
        EXCEL_FILE_IMPORT_TEST_FAILURE,
      ],
      endpoint: "/api/excel/fileImport/test",
      method: "POST",
      body: data,
    },
  });
};

export const excelFileImport = (file) => (dispatch) => {
  var data = new FormData();
  data.append("file", file);
  return dispatch({
    [CALL_API]: {
      types: [
        EXCEL_FILE_IMPORT_REQUEST,
        EXCEL_FILE_IMPORT_SUCCESS,
        EXCEL_FILE_IMPORT_FAILURE,
      ],
      endpoint: "/api/excel/fileImport/import",
      method: "POST",
      body: data,
    },
  });
};

export const excelFileImportLettureTest = (file) => (dispatch) => {
  var data = new FormData();
  data.append("file", file);
  return dispatch({
    [CALL_API]: {
      types: [
        EXCEL_FILE_IMPORT_LETTURE_TEST_REQUEST,
        EXCEL_FILE_IMPORT_LETTURE_TEST_SUCCESS,
        EXCEL_FILE_IMPORT_LETTURE_TEST_FAILURE,
      ],
      endpoint: "/api/excel/fileImportLetture/test",
      method: "POST",
      body: data,
    },
  });
};

export const excelFileImportLetture = (file) => (dispatch) => {
  var data = new FormData();
  data.append("file", file);
  return dispatch({
    [CALL_API]: {
      types: [
        EXCEL_FILE_IMPORT_LETTURE_REQUEST,
        EXCEL_FILE_IMPORT_LETTURE_SUCCESS,
        EXCEL_FILE_IMPORT_LETTURE_FAILURE,
      ],
      endpoint: "/api/excel/fileImportLetture/import",
      method: "POST",
      body: data,
    },
  });
};

export const dichiarazioneFileImportTest = (file) => (dispatch) => {
  var data = new FormData();
  data.append("file", file);
  return dispatch({
    [CALL_API]: {
      types: [
        DICHIARAZIONE_FILE_IMPORT_TEST_REQUEST,
        DICHIARAZIONE_FILE_IMPORT_TEST_SUCCESS,
        DICHIARAZIONE_FILE_IMPORT_TEST_FAILURE,
      ],
      endpoint: "/api/dichiarazione/fileImport/test",
      method: "POST",
      body: data,
    },
  });
};

export const dichiarazioneFileImport =
  (file, selectedType, azienda) => (dispatch) => {
    const data = new FormData();
    data.append("file", file);
    data.append("selectedType", selectedType);
    data.append("aziendaJson", JSON.stringify(azienda));
    return dispatch({
      [CALL_API]: {
        types: [
          DICHIARAZIONE_FILE_IMPORT_REQUEST,
          DICHIARAZIONE_FILE_IMPORT_SUCCESS,
          DICHIARAZIONE_FILE_IMPORT_FAILURE,
        ],
        endpoint: "/api/dichiarazione/fileImport/import",
        method: "POST",
        body: data,
      },
    });
  };

export const venditoreFileImportTest = (file) => (dispatch) => {
  var data = new FormData();
  data.append("file", file);
  return dispatch({
    [CALL_API]: {
      types: [
        VENDITORE_FILE_IMPORT_TEST_REQUEST,
        VENDITORE_FILE_IMPORT_TEST_SUCCESS,
        VENDITORE_FILE_IMPORT_TEST_FAILURE,
      ],
      endpoint: "/api/venditore/fileImport/test",
      method: "POST",
      body: data,
    },
  });
};

export const venditoreFileImport = (file, selectedType) => (dispatch) => {
  var data = new FormData();
  data.append("file", file);
  data.append("selectedType", selectedType);
  return dispatch({
    [CALL_API]: {
      types: [
        VENDITORE_FILE_IMPORT_REQUEST,
        VENDITORE_FILE_IMPORT_SUCCESS,
        VENDITORE_FILE_IMPORT_FAILURE,
      ],
      endpoint: "/api/venditore/fileImport/import",
      method: "POST",
      body: data,
    },
  });
};

export const xmlSignedFileImport_REQUEST = "xmlSignedFileImport_REQUEST";
export const xmlSignedFileImport_SUCCESS = "xmlSignedFileImport_SUCCESS";
export const xmlSignedFileImport_FAILURE = "xmlSignedFileImport_FAILURE";

export const xmlSignedFileImport = (files) => (dispatch) => {
  var data = new FormData();
  data.append("numeroFile", files.length);
  files.forEach((fileObj, index) => {
    const { file, anno, installation, report } = fileObj;
    data.append("file[]", file);
    data.append("anno[]", anno);
    data.append("installationId[]", installation.id);
    data.append("reportId[]", report ? report.id : null);
  });

  return dispatch({
    [CALL_API]: {
      types: [
        xmlSignedFileImport_REQUEST,
        xmlSignedFileImport_SUCCESS,
        xmlSignedFileImport_FAILURE,
      ],
      endpoint: "/api/report/import/fileXmlFirmati",
      method: "POST",
      body: data,
    },
  });
};

export const sendReport = (reportId, ambienteReale) => (dispatch) => {
  const endpoint =
    "/api/report/" + reportId + "/send/" + (ambienteReale ? "reale" : "prova");
  return dispatch({
    [CALL_API]: {
      types: [SEND_REPORT_REQUEST, SEND_REPORT_SUCCESS, SEND_REPORT_FAILURE],
      endpoint: endpoint,
      method: "POST",
    },
    ambienteReale: ambienteReale,
  });
};

// export const generaReportClientWebsiteLink =
//   (reportId) => (dispatch) => {
//     const endpoint = "/api/report/" + reportId + "/generaClientWebsiteLink";
//     return dispatch({
//       [CALL_API]: {
//         types: [
//           REPORT_generaClientWebsiteLink_REQUEST,
//           REPORT_generaClientWebsiteLink_SUCCESS,
//           REPORT_generaClientWebsiteLink_FAILURE,
//         ],
//         endpoint: endpoint,
//         method: "POST",
//       },
//     });
//   };

export const sendReportClientWebsiteLettureLinkByEmail =
  (idDichiarazione) => (dispatch) => {
    const endpoint = `/api/report/${idDichiarazione}/sendClientWebsiteLettureLinkByEmail`;
    return dispatch({
      [CALL_API]: {
        types: [
          REPORT_sendClientWebsiteLettureLinkByEmail_REQUEST,
          REPORT_sendClientWebsiteLettureLinkByEmail_SUCCESS,
          REPORT_sendClientWebsiteLettureLinkByEmail_FAILURE,
        ],
        endpoint: endpoint,
        method: "POST",
      },
    });
  };

export const sendReportClientWebsiteFirmaXmlLinkByEmail =
  (idDichiarazione) => (dispatch) => {
    const endpoint = `/api/report/${idDichiarazione}/sendClientWebsiteFirmaXmlLinkByEmail`;
    return dispatch({
      [CALL_API]: {
        types: [
          REPORT_sendClientWebsiteFirmaXmlLinkByEmail_REQUEST,
          REPORT_sendClientWebsiteFirmaXmlLinkByEmail_SUCCESS,
          REPORT_sendClientWebsiteFirmaXmlLinkByEmail_FAILURE,
        ],
        endpoint: endpoint,
        method: "POST",
      },
    });
  };

// export const REPORT_inviaEmailConfermaInvioReport_REQUEST =
//   "REPORT_inviaEmailConfermaInvioReport_REQUEST";
// export const REPORT_inviaEmailConfermaInvioReport_SUCCESS =
//   "REPORT_inviaEmailConfermaInvioReport_SUCCESS";
// export const REPORT_inviaEmailConfermaInvioReport_FAILURE =
//   "REPORT_inviaEmailConfermaInvioReport_FAILURE";
//
// export const inviaEmailConfermaInvioReport =
//   (reportId, notificationCode) => (dispatch) => {
//     const endpoint =
//       "/api/report/" +
//       reportId +
//       "/emailConfermaInvio/" +
//       notificationCode +
//       "/send";
//     return dispatch({
//       [CALL_API]: {
//         types: [
//           REPORT_inviaEmailConfermaInvioReport_REQUEST,
//           REPORT_inviaEmailConfermaInvioReport_SUCCESS,
//           REPORT_inviaEmailConfermaInvioReport_FAILURE,
//         ],
//         endpoint: endpoint,
//         method: "POST",
//       },
//     });
//   };

export const NOTIFICATION_DEFAULT_DELETE_REQUEST =
  "NOTIFICATION_DEFAULT_DELETE_REQUEST";
export const NOTIFICATION_DEFAULT_DELETE_SUCCESS =
  "NOTIFICATION_DEFAULT_DELETE_SUCCESS";
export const NOTIFICATION_DEFAULT_DELETE_FAILURE =
  "NOTIFICATION_DEFAULT_DELETE_FAILURE";

export const deleteNotificationDefault = (notificationId) => (dispatch) => {
  return dispatch({
    notificationId: notificationId,
    [CALL_API]: {
      types: [
        NOTIFICATION_DEFAULT_DELETE_REQUEST,
        NOTIFICATION_DEFAULT_DELETE_SUCCESS,
        NOTIFICATION_DEFAULT_DELETE_FAILURE,
      ],
      endpoint: "/api/notification-default/" + notificationId,
      method: "DELETE",
    },
  });
};

export const GET_NOTIFICATION_SENT_FOR_INSTALLATION_REQUEST =
  "GET_NOTIFICATION_SENT_FOR_INSTALLATION_REQUEST";
export const GET_NOTIFICATION_SENT_FOR_INSTALLATION_SUCCESS =
  "GET_NOTIFICATION_SENT_FOR_INSTALLATION_SUCCESS";
export const GET_NOTIFICATION_SENT_FOR_INSTALLATION_FAILURE =
  "GET_NOTIFICATION_SENT_FOR_INSTALLATION_FAILURE";

export const getNotificationSentForInstallation =
  (installationId) => (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [
          GET_NOTIFICATION_SENT_FOR_INSTALLATION_REQUEST,
          GET_NOTIFICATION_SENT_FOR_INSTALLATION_SUCCESS,
          GET_NOTIFICATION_SENT_FOR_INSTALLATION_FAILURE,
        ],
        endpoint: "/api/notificationSent/installation/" + installationId,
        method: "GET",
      },
    });
  };

export const GET_NOTIFICATION_SENT_REQUEST = "GET_NOTIFICATION_SENT_REQUEST";
export const GET_NOTIFICATION_SENT_SUCCESS = "GET_NOTIFICATION_SENT_SUCCESS";
export const GET_NOTIFICATION_SENT_FAILURE = "GET_NOTIFICATION_SENT_FAILURE";

export const getNotificationSent = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        GET_NOTIFICATION_SENT_REQUEST,
        GET_NOTIFICATION_SENT_SUCCESS,
        GET_NOTIFICATION_SENT_FAILURE,
      ],
      endpoint: `/api/notificationSent/log`,
      method: "GET",
    },
  });
};

export const GET_PACCHETTI_TOKEN_REQUEST = "GET_PACCHETTI_TOKEN_REQUEST";
export const GET_PACCHETTI_TOKEN_SUCCESS = "GET_PACCHETTI_TOKEN_SUCCESS";
export const GET_PACCHETTI_TOKEN_FAILURE = "GET_PACCHETTI_TOKEN_FAILURE";

export const getPacchettiToken = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        GET_PACCHETTI_TOKEN_REQUEST,
        GET_PACCHETTI_TOKEN_SUCCESS,
        GET_PACCHETTI_TOKEN_FAILURE,
      ],
      endpoint: `/api/pacchettiToken`,
      method: "GET",
    },
  });
};

export const GET_TRANSAZIONI_TOKEN_REQUEST = "GET_TRANSAZIONI_TOKEN_REQUEST";
export const GET_TRANSAZIONI_TOKEN_SUCCESS = "GET_TRANSAZIONI_TOKEN_SUCCESS";
export const GET_TRANSAZIONI_TOKEN_FAILURE = "GET_TRANSAZIONI_TOKEN_FAILURE";

export const getTransazioniToken = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        GET_TRANSAZIONI_TOKEN_REQUEST,
        GET_TRANSAZIONI_TOKEN_SUCCESS,
        GET_TRANSAZIONI_TOKEN_FAILURE,
      ],
      endpoint: `/api/transazioniToken`,
      method: "GET",
    },
  });
};

export const GET_CONTRATTI_REQUEST = "GET_CONTRATTI_REQUEST";
export const GET_CONTRATTI_SUCCESS = "GET_CONTRATTI_SUCCESS";
export const GET_CONTRATTI_FAILURE = "GET_CONTRATTI_FAILURE";

export const getContratti = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        GET_CONTRATTI_REQUEST,
        GET_CONTRATTI_SUCCESS,
        GET_CONTRATTI_FAILURE,
      ],
      endpoint: `/api/contratti`,
      method: "GET",
    },
  });
};

export const RETRY_SEND_NOTIFICATION_EMAIL_REQUEST =
  "RETRY_SEND_NOTIFICATION_EMAIL_REQUEST";
export const RETRY_SEND_NOTIFICATION_EMAIL_SUCCESS =
  "RETRY_SEND_NOTIFICATION_EMAIL_SUCCESS";
export const RETRY_SEND_NOTIFICATION_EMAIL_FAILURE =
  "RETRY_SEND_NOTIFICATION_EMAIL_FAILURE";

export const retrySendNotificationEmail =
  (notificationSentId) => (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [
          RETRY_SEND_NOTIFICATION_EMAIL_REQUEST,
          RETRY_SEND_NOTIFICATION_EMAIL_SUCCESS,
          RETRY_SEND_NOTIFICATION_EMAIL_FAILURE,
        ],
        endpoint: "/api/notificationSent/" + notificationSentId + "/sendEmail",
        method: "POST",
      },
    });
  };

export const SERVIZI_ACQUISTATI_ABILITA_DISABILITA_REQUEST =
  "SERVIZI_ACQUISTATI_ABILITA_DISABILITA_REQUEST";
export const SERVIZI_ACQUISTATI_ABILITA_DISABILITA_SUCCESS =
  "SERVIZI_ACQUISTATI_ABILITA_DISABILITA_SUCCESS";
export const SERVIZI_ACQUISTATI_ABILITA_DISABILITA_FAILURE =
  "SERVIZI_ACQUISTATI_ABILITA_DISABILITA_FAILURE";

export const serviziAcquistatiAbilitaDisabilita =
  (servizioType, body) => (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [
          SERVIZI_ACQUISTATI_ABILITA_DISABILITA_REQUEST,
          SERVIZI_ACQUISTATI_ABILITA_DISABILITA_SUCCESS,
          SERVIZI_ACQUISTATI_ABILITA_DISABILITA_FAILURE,
        ],
        endpoint:
          "/api/serviziAcquistati/" + servizioType + "/abilitaDisabilita",
        method: "POST",
        body: body,
      },
    });
  };

export const updateAziendaServizio_REQUEST = "updateAziendaServizio_REQUEST";
export const updateAziendaServizio_SUCCESS = "updateAziendaServizio_SUCCESS";
export const updateAziendaServizio_FAILURE = "updateAziendaServizio_FAILURE";

export const updateAziendaServizio = (serviceType, params) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        updateAziendaServizio_REQUEST,
        updateAziendaServizio_SUCCESS,
        updateAziendaServizio_FAILURE,
      ],
      endpoint: "/api/serviceLicense/updateServizio/" + serviceType,
      method: "POST",
      body: params,
    },
  });
};

export const setPartnerRelax_REQUEST = "setPartnerRelax_REQUEST";
export const setPartnerRelax_SUCCESS = "setPartnerRelax_SUCCESS";
export const setPartnerRelax_FAILURE = "setPartnerRelax_FAILURE";

export const setPartnerRelax = (userId, isRelax) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        setPartnerRelax_REQUEST,
        setPartnerRelax_SUCCESS,
        setPartnerRelax_FAILURE,
      ],
      endpoint: "/api/service/setPartnerRelax",
      method: "POST",
      body: {
        userId,
        isRelax,
      },
    },
  });
};

export const setVenditore_REQUEST = "setVenditore_REQUEST";
export const setVenditore_SUCCESS = "setVenditore_SUCCESS";
export const setVenditore_FAILURE = "setVenditore_FAILURE";

export const setVenditore = (userId, venditore) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [setVenditore_REQUEST, setVenditore_SUCCESS, setVenditore_FAILURE],
      endpoint: "/api/user/setVenditore",
      method: "POST",
      body: {
        userId,
        venditore,
      },
    },
  });
};

export const getNews_REQUEST = "getNews_REQUEST";
export const getNews_SUCCESS = "getNews_SUCCESS";
export const getNews_FAILURE = "getNews_FAILURE";
export const setNewsRead_REQUEST = "setNewsRead_REQUEST";
export const setNewsRead_SUCCESS = "setNewsRead_SUCCESS";
export const setNewsRead_FAILURE = "setNewsRead_FAILURE";

export const getNews = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [getNews_REQUEST, getNews_SUCCESS, getNews_FAILURE],
      endpoint: "/api/news/popup/list",
      method: "GET",
    },
  });
};

export const setNewsRead = (newsId) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [setNewsRead_REQUEST, setNewsRead_SUCCESS, setNewsRead_FAILURE],
      endpoint: "/api/news/" + newsId + "/popup/read",
      method: "POST",
      body: {},
    },
  });
};

// NON SERVE A NIENTE TANTO......
export const general_REQUEST = "general_REQUEST";
export const general_SUCCESS = "general_SUCCESS";
export const general_FAILURE = "general_FAILURE";

export const getAdminStatsDichiarazioni = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [general_REQUEST, general_SUCCESS, general_FAILURE],
      endpoint: "/api/admin/stats/dichiarazioni",
      method: "GET",
    },
  });
};

export const getAdminStatsDichiarazioniGraph = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [general_REQUEST, general_SUCCESS, general_FAILURE],
      endpoint: "/api/admin/stats/dichiarazioni/graph",
      method: "GET",
    },
  });
};

export const getPartnerStatsDichiarazioniGraph = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [general_REQUEST, general_SUCCESS, general_FAILURE],
      endpoint: "/api/partner/stats/dichiarazioni/graph",
      method: "GET",
    },
  });
};

export const getAdminStatsDichiarazioniType = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [general_REQUEST, general_SUCCESS, general_FAILURE],
      endpoint: "/api/admin/stats/dichiarazioni/types",
      method: "GET",
    },
  });
};

export const getPartnerStatsDichiarazioniType = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [general_REQUEST, general_SUCCESS, general_FAILURE],
      endpoint: "/api/partner/stats/dichiarazioni/types",
      method: "GET",
    },
  });
};

export const getFAQ_REQUEST = "getFAQ_REQUEST";
export const getFAQ_SUCCESS = "getFAQ_SUCCESS";
export const getFAQ_FAILURE = "getFAQ_FAILURE";

export const getFAQ = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [getFAQ_REQUEST, getFAQ_SUCCESS, getFAQ_FAILURE],
      endpoint: "/api/faq",
      method: "GET",
    },
  });
};

export const updateFAQ_REQUEST = "updateFAQ_REQUEST";
export const updateFAQ_SUCCESS = "updateFAQ_SUCCESS";
export const updateFAQ_FAILURE = "updateFAQ_FAILURE";

export const updateFAQ = (body) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [updateFAQ_REQUEST, updateFAQ_SUCCESS, updateFAQ_FAILURE],
      endpoint: "/api/admin/faq",
      method: "PUT",
      body: body,
    },
  });
};

export const getFunzionalita_REQUEST = "getFunzionalita_REQUEST";
export const getFunzionalita_SUCCESS = "getFunzionalita_SUCCESS";
export const getFunzionalita_FAILURE = "getFunzionalita_FAILURE";

export const getFunzionalita = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        getFunzionalita_REQUEST,
        getFunzionalita_SUCCESS,
        getFunzionalita_FAILURE,
      ],
      endpoint: "/api/funzionalita",
      method: "GET",
    },
  });
};

export const USERS_GET_REQUEST = "USERS_GET_REQUEST";
export const USERS_GET_SUCCESS = "USERS_GET_SUCCESS";
export const USERS_GET_FAILURE = "USERS_GET_FAILURE";

export const getUsers = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [USERS_GET_REQUEST, USERS_GET_SUCCESS, USERS_GET_FAILURE],
      endpoint: "/api/admin/clients",
      method: "GET",
    },
  });
};

export const RICARICHE_GET_REQUEST = "RICARICHE_GET_REQUEST";
export const RICARICHE_GET_SUCCESS = "RICARICHE_GET_SUCCESS";
export const RICARICHE_GET_FAILURE = "RICARICHE_GET_FAILURE";

export const getRicariche = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        RICARICHE_GET_REQUEST,
        RICARICHE_GET_SUCCESS,
        RICARICHE_GET_FAILURE,
      ],
      endpoint: "/api/transazioniToken/ricariche",
      method: "GET",
    },
  });
};

export const IMPIANTI_GREEN_GET_REQUEST = "IMPIANTI_GREEN_GET_REQUEST";
export const IMPIANTI_GREEN_GET_SUCCESS = "IMPIANTI_GREEN_GET_SUCCESS";
export const IMPIANTI_GREEN_GET_FAILURE = "IMPIANTI_GREEN_GET_FAILURE";

export const getImpiantiGreen = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        IMPIANTI_GREEN_GET_REQUEST,
        IMPIANTI_GREEN_GET_SUCCESS,
        IMPIANTI_GREEN_GET_FAILURE,
      ],
      endpoint: "/api/impianti-green",
      method: "GET",
    },
  });
};

export const IMPIANTO_GREEN_GET_REQUEST = "IMPIANTO_GREEN_GET_REQUEST";
export const IMPIANTO_GREEN_GET_SUCCESS = "IMPIANTO_GREEN_GET_SUCCESS";
export const IMPIANTO_GREEN_GET_FAILURE = "IMPIANTO_GREEN_GET_FAILURE";

export const getImpiantoGreen = (idImpianto) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        IMPIANTO_GREEN_GET_REQUEST,
        IMPIANTO_GREEN_GET_SUCCESS,
        IMPIANTO_GREEN_GET_FAILURE,
      ],
      endpoint: `/api/impianti-green/${idImpianto}`,
      method: "GET",
    },
  });
};

export const updateFunzionalita_REQUEST = "updateFunzionalita_REQUEST";
export const updateFunzionalita_SUCCESS = "updateFunzionalita_SUCCESS";
export const updateFunzionalita_FAILURE = "updateFunzionalita_FAILURE";

export const updateFunzionalita = (body) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        updateFunzionalita_REQUEST,
        updateFunzionalita_SUCCESS,
        updateFunzionalita_FAILURE,
      ],
      endpoint: "/api/admin/funzionalita",
      method: "PUT",
      body: body,
    },
  });
};

export const getFunzionalitaMostrate_REQUEST =
  "getFunzionalitaMostrate_REQUEST";
export const getFunzionalitaMostrate_SUCCESS =
  "getFunzionalitaMostrate_SUCCESS";
export const getFunzionalitaMostrate_FAILURE =
  "getFunzionalitaMostrate_FAILURE";

export const getFunzionalitaMostrate = () => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        getFunzionalitaMostrate_REQUEST,
        getFunzionalitaMostrate_SUCCESS,
        getFunzionalitaMostrate_FAILURE,
      ],
      endpoint: "/api/partner/funzionalitaMostrate",
      method: "GET",
    },
  });
};

export const createFunzionalitaMostrata_REQUEST =
  "createFunzionalitaMostrata_REQUEST";
export const createFunzionalitaMostrata_SUCCESS =
  "createFunzionalitaMostrata_SUCCESS";
export const createFunzionalitaMostrata_FAILURE =
  "createFunzionalitaMostrata_FAILURE";

export const createFunzionalitaMostrata = (funzionalita) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [
        createFunzionalitaMostrata_REQUEST,
        createFunzionalitaMostrata_SUCCESS,
        createFunzionalitaMostrata_FAILURE,
      ],
      endpoint: `/api/partner/funzionalitaMostrate?funzionalitaId=${funzionalita.id}`,
      method: "POST",
    },
  });
};

export const getAvvisi_REQUEST = "getAvvisi_REQUEST";
export const getAvvisi_SUCCESS = "getAvvisi_SUCCESS";
export const getAvvisi_FAILURE = "getAvvisi_FAILURE";

export const getAvvisi =
  (includeNotAbilitati = false) =>
  (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [getAvvisi_REQUEST, getAvvisi_SUCCESS, getAvvisi_FAILURE],
        endpoint: `/api/avvisi?includeNotAbilitati=${includeNotAbilitati}`,
        method: "GET",
      },
    });
  };

export const updateAvvisi_REQUEST = "updateAvvisi_REQUEST";
export const updateAvvisi_SUCCESS = "updateAvvisi_SUCCESS";
export const updateAvvisi_FAILURE = "updateAvvisi_FAILURE";

export const updateAvvisi = (body) => (dispatch) => {
  return dispatch({
    [CALL_API]: {
      types: [updateAvvisi_REQUEST, updateAvvisi_SUCCESS, updateAvvisi_FAILURE],
      endpoint: "/api/admin/avvisi",
      method: "PUT",
      body: body,
    },
  });
};
