import { datiByCodiceCatastale } from "common/datiIstat";
import {
  parteComune,
  stringToLength,
  intToString,
  numToString_IntegerAndDecimalPart,
  xml_numToString_IntegerAndDecimalPart,
  getQuadroTypeForQuadro,
  getRootXmlTypeForQuadro,
  isPre2021,
} from "./c01Utils";
import { formuleVariablesKeys } from "./calcoloPerUsoProprio";
import { getDatiQuadri } from "./lettureUtils";
import { checkHasFormula, haQuadro } from "./quadriUtils";
import { each } from "lodash";

function roundFloat0DecWithFix(t) {
  /*
  example:
    > var t = 3253.14
    > t/12
    271.09499999999997
    > Math.round(t/12*100)/100
    271.09
    > (t/12).toFixed(5)
    '271.09500'
    > Math.round(parseFloat((t/12).toFixed(8))*100)/100
    271.1
  */
  return Math.round(parseFloat(t.toFixed(8)));
}

function checkResultAggregatedGreaterThen0(resultAggregated, fail) {
  //TODO:
  resultAggregated.forEach((resultObj) => {
    if (resultObj.chilowattora < 0) {
      if (fail) {
        throw new Error("Il totale dei chilowattora è minore di zero.");
      }
    }
  });
}

function calculateResultAggregated_casoConFormule(
  installation,
  serviceLicense,
  report,
  formulaType,
  calcoloPerUsoProprio,
  fail
) {
  const key = formuleVariablesKeys[formulaType];
  const values = calcoloPerUsoProprio[key];

  const codiceCatastaleComune = installation.codiceCatastaleImpianto;
  const tipologiaFornitura = report.reportInfo.tipologiaFornitura;

  const result = [];
  each(values, (value, mese) => {
    mese = parseInt(mese);

    const info = {
      mese: mese,
      codiceCatastaleComune: codiceCatastaleComune,
      tipologiaFornitura: tipologiaFornitura,
      chilowattora: value, // value is not rounded! (modifica 02/03/2020)
    };

    result.push(info);
  });

  let resultAggregated = [];
  const resultAggregatedByKey = {};

  // aggregate
  result.forEach((row) => {
    const key = row.codiceCatastaleComune + "--" + row.tipologiaFornitura; // è annuale!! + '--' + row.mese
    let obj = resultAggregatedByKey[key];
    if (!obj) {
      obj = {
        codiceCatastaleComune: row.codiceCatastaleComune,
        tipologiaFornitura: row.tipologiaFornitura,
        chilowattora: 0,
      };
      var c = datiByCodiceCatastale[row.codiceCatastaleComune];
      obj.comune = c.comuneRaw;
      obj.provincia = c.provincia;

      resultAggregatedByKey[key] = obj;
      resultAggregated.push(obj);
    }

    obj.chilowattora += row.chilowattora;
  });

  // modifica 02/03/2020
  // arrotonda solo ora i chilowattora, cosi sono arrotondati a livello di mese ma non prima di fare la somma dei chilowattora di tutti i contatori
  resultAggregated.forEach((resultObj) => {
    resultObj.chilowattora = roundFloat0DecWithFix(resultObj.chilowattora);
  });

  // sort
  resultAggregated = resultAggregated.sort(
    (
      { codiceCatastaleComune: codiceCatastaleComune1, mese: mese1 },
      { codiceCatastaleComune: codiceCatastaleComune2, mese: mese2 }
    ) => {
      if (codiceCatastaleComune1 !== codiceCatastaleComune2) {
        return codiceCatastaleComune1 < codiceCatastaleComune2 ? -1 : 1;
      }
      return mese1 - mese2;
    }
  );

  return resultAggregated;
}

export default function generateQuadroI(
  quadroType,
  installation,
  serviceLicense,
  report,
  calcoloPerUsoProprio,
  fail
) {
  const datiQuadri = getDatiQuadri(
    report,
    "energiaElettricaFatturata",
    "contatoreQuantita"
  );
  if (!calcoloPerUsoProprio && datiQuadri.length === 0) {
    return [];
  }
  if (datiQuadri.length === 0 && !haQuadro("I", installation.type, report)) {
    return [];
  }

  let resultAggregated;
  if (datiQuadri.length === 0) {
    if (
      !report.reportInfo ||
      !/^[LMNOPQ]$/.test(report.reportInfo.tipologiaFornitura)
    ) {
      if (fail) {
        throw new Error("L'impianto deve avere una tipologia di fornitura");
      }
    }

    resultAggregated = calculateResultAggregated_casoConFormule(
      installation,
      serviceLicense,
      report,
      "ENERGIA_ELETTRICA_FATTURATA",
      calcoloPerUsoProprio,
      fail
    );
  } else {
    resultAggregated = [];
    datiQuadri.forEach((d) => {
      resultAggregated.push({
        codiceCatastaleComune: d.codiceCatastale,
        _codiceIdentificativoOfficinaDistributrice: d.codiceIdentificativo,
        tipologiaFornitura: d.tipologia,
        chilowattora: d.chilowattora,
      });
    });
  }

  // resultAggregated = applyOverrideQuadri('I', report, resultAggregated, 'chilowattora')

  checkResultAggregatedGreaterThen0(resultAggregated, fail);

  const result = [];
  const contatoriXmls = [];
  let total_chilowattora = 0;

  resultAggregated.forEach(
    ({
      codiceCatastaleComune,
      tipologiaFornitura,
      chilowattora,
      _codiceIdentificativoOfficinaDistributrice,
    }) => {
      total_chilowattora += chilowattora;

      const codiceIdentificativoOfficina =
        datiQuadri.length === 0
          ? report.reportInfo.codiceIdentificativoOfficinaDistributrice
          : _codiceIdentificativoOfficinaDistributrice;

      const info = {
        chilowattora: chilowattora,
        tipologiaFornitura: tipologiaFornitura,
        codiceIdentificativoOfficina: codiceIdentificativoOfficina,
        codiceCatastaleComune: codiceCatastaleComune,
      };

      const otherXmls = [];
      if (codiceCatastaleComune) {
        otherXmls.push({
          xmlType: isPre2021(report) ? "CodiceCatastale" : "CodCat",
          content: codiceCatastaleComune,
        });
      } else {
        otherXmls.push({
          xmlType: isPre2021(report) ? "CodiceCatastale" : "CodCat",
          content: "",
        });
      }

      info.c01 = "DIC QUADRO I NON IMPLEMENTATO";

      contatoriXmls.push({
        xmlType: isPre2021(report) ? "ContatoreQuantita" : "Contatore",
        children: [
          isPre2021(report) && {
            xmlType: "Matricola",
            content: "",
          },
          {
            xmlType: isPre2021(report) ? "Tipologia" : "Tipo",
            content: info.tipologiaFornitura,
          },
          {
            xmlType: isPre2021(report) ? "CodiceIdentificativo" : "Id",
            content: info.codiceIdentificativoOfficina,
          },
          {
            xmlType: isPre2021(report) ? "Chilowattora" : "Kwh",
            content: info.chilowattora,
          },
          ...otherXmls,
        ].filter((x) => x),
      });

      result.push(info);
    }
  );

  const totalInfo = {
    totale: true,
    chilowattora: total_chilowattora,
  };
  totalInfo.c01 = "DIC QUADRO I NON IMPLEMENTATO";

  result.push(totalInfo);

  if (isPre2021(report)) {
    const otherTotaleXmls = [];
    otherTotaleXmls.push({
      xmlType: "CodiceCatastale",
      content: "",
    });

    contatoriXmls.push({
      xmlType: "ContatoreQuantita",
      children: [
        {
          xmlType: "Matricola",
          content: "999999999999999",
        },
        {
          xmlType: "Tipologia",
          content: "",
        },
        {
          xmlType: "CodiceIdentificativo",
          content: "",
        },
        {
          xmlType: "Chilowattora",
          content: totalInfo.chilowattora,
        },
        ...otherTotaleXmls,
      ],
    });

    // xml
    result[0].xml = {
      xmlType: getRootXmlTypeForQuadro(quadroType, isPre2021(report)),
      children: [
        {
          xmlType: "TipoRecord",
          content: getQuadroTypeForQuadro(quadroType),
        },
        {
          xmlType: "Mese",
          content: 0,
        },
        ...contatoriXmls,
      ],
    };
  } else {
    // xml
    result[0].xml = {
      xmlType: getRootXmlTypeForQuadro(quadroType, isPre2021(report)),
      children: [
        ...contatoriXmls,
        {
          xmlType: "Totale",
          content: totalInfo.chilowattora,
        },
      ],
    };
  }

  return result;
}
