// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = "CALL_API";

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = (endpoint, method, body, schema) => {
  let headers = {
    Accept: "application/json",
  };
  if (body && body instanceof FormData) {
    body = body;
  } else if (body) {
    body = JSON.stringify(body);
    headers["Content-Type"] = "application/json";
  }
  return fetch(endpoint, {
    method,
    body,
    headers: headers,
    credentials: "same-origin",
  }).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }
      return json;
    })
  );
};

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default (store) => (next) => (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  let { endpoint, method } = callAPI;
  const { body, schema, types } = callAPI;

  if (typeof endpoint === "function") {
    endpoint = endpoint(store.getState());
  }

  method = method || "GET";

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;

  next(actionWith({ type: requestType }));

  return callApi(endpoint, method, body, schema).then(
    (response) =>
      next(
        actionWith({
          type: successType,
          callApiSuccess: true,
          response,
        })
      ),
    (error) =>
      next(
        actionWith({
          type: failureType,
          callApiSuccess: false,
          response:
            error instanceof Error && error.message === "Failed to fetch"
              ? { message: "Connection error. Try again later." }
              : error,
        })
      )
  );
};
