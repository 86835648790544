import React, { ReactNode } from "react";
import "simplebar/src/simplebar.css";
import ThemeConfig from "./theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toast.css";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import "moment/locale/it";
import numeral from "numeral";
import "numeral/locales/it";

// serve intanto che si usa anche qualcosa sotto energix-old
import "energix-old/src/common/frontendV2Setup";

moment.locale("it");
numeral.locale("it");

function ToastCloseButton() {
  return <span>{"×"}</span>;
}

export default function AppWrapper({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  return (
    <ThemeConfig>
      <ToastContainer
        toastClassName="app-toast"
        closeButton={<ToastCloseButton />}
      />
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"it"}>
        {children}
      </LocalizationProvider>
    </ThemeConfig>
  );
}
