import { get, orderBy, cloneDeep, last, some, find } from "lodash";
import { installationsByType } from "./installations";

function createLetture(anno, oldletture) {
  const key = "L" + anno + "0101";
  return oldletture && oldletture[key]
    ? {
        [key]: oldletture[key],
      }
    : {};
}

function buildContatori(installation, anno, allContatori, allReports) {
  const lastReport = find(allReports, (r) => r.anno === anno - 1);
  const lastReportContatori =
    lastReport && lastReport.reportInfo && lastReport.reportInfo.contatori;
  const contatori = [];

  lastReportContatori &&
    lastReportContatori.forEach((contatore) => {
      let newContatore;

      if (!contatore.endDate || contatore.endDate >= anno - 2 + "-12-31") {
        newContatore = {
          ...contatore,
          letture: createLetture(anno - 1, contatore.letture),
        };
      }

      if (newContatore) {
        // start with empty start and end date
        delete newContatore.startDate;
        delete newContatore.endDate;
        contatori.push(newContatore);
      }
    });

  // allContatori.forEach(contatore => {
  //   let newContatore

  //   const infoThisYear = get(contatore, 'contatoreInfo.byAnno.' + (anno-1))
  //   if(infoThisYear){
  //     newContatore = {
  //       id: contatore.id,
  //       ...infoThisYear
  //     }
  //   }else{
  //     const infoLastYear = get(contatore, 'contatoreInfo.byAnno.' + (anno-2))
  //     if(infoLastYear){
  //       if(!infoLastYear.endDate || infoLastYear.endDate >= ((anno-2) + '-12-31')) {
  //         newContatore = {
  //           id: contatore.id,
  //           ...infoLastYear,
  //           letture: createLetture(anno-1, infoLastYear.letture)
  //         }
  //       }
  //     }
  //   }

  //   if(newContatore) {
  //     // start with empty start and end date
  //     delete newContatore.startDate
  //     delete newContatore.endDate
  //     contatori.push(newContatore)
  //   }
  // })

  return contatori;
}

function buildFormule(anno, allReports) {
  allReports = orderBy(allReports, "anno", "desc");

  let formule = null;

  for (let index = 0; index < allReports.length; index++) {
    const report = allReports[index];
    if (report.anno > anno) {
      continue;
    }
    if (report.anno === anno) {
      formule = cloneDeep(get(report, "reportInfo.formule")) || [];
    } else {
      const lastFormula = last(get(report, "reportInfo.formule") || []);
      if (lastFormula) {
        const newFormula = {
          ...lastFormula,
          startDate: anno - 1 + "-01-01",
          endDate: anno - 1 + "-12-31",
        };
        formule = [newFormula];
      }
    }
    break;
  }

  if (!formule || formule.length === 0) {
    formule = [
      {
        startDate: anno - 1 + "-01-01",
        endDate: anno - 1 + "-12-31",
      },
    ];
  }

  return formule;
}

function buildCostanti(anno, allReports) {
  allReports = orderBy(allReports, "anno", "desc");

  let costanti = null;

  for (let index = 0; index < allReports.length; index++) {
    const report = allReports[index];
    if (report.anno > anno) {
      continue;
    }
    costanti = cloneDeep(get(report, "reportInfo.costanti")) || [];
    break;
  }

  return costanti || [];
}

async function getAcconti(anno, allReports, getAccontiReport) {
  allReports = orderBy(allReports, "anno", "desc");

  for (let index = 0; index < allReports.length; index++) {
    const report = allReports[index];
    if (report.anno >= anno) {
      continue;
    }
    if (report.anno !== anno - 1) {
      break;
    }
    const accontiAction = await getAccontiReport(report.id);
    if (
      accontiAction &&
      accontiAction.response &&
      accontiAction.response.success
    ) {
      return accontiAction.response.data || null;
    }
    break;
  }

  return null;
}

async function getAccontiExcelFileContent(
  anno,
  allReports,
  getAccontiExcelFileContentReport
) {
  allReports = orderBy(allReports, "anno", "desc");

  for (let index = 0; index < allReports.length; index++) {
    const report = allReports[index];
    if (report.anno >= anno) {
      continue;
    }
    if (report.anno !== anno - 1) {
      break;
    }
    const accontiAction = await getAccontiExcelFileContentReport(report.id);
    if (
      accontiAction &&
      accontiAction.response &&
      accontiAction.response.success
    ) {
      return accontiAction.response.data || null;
    }
    break;
  }

  return null;
}

export function createNextReportSyncWithoutAcconti(
  installation,
  anno,
  allReports,
  allContatori
) {
  const report = {};
  report.installation_id = installation.id;
  report.type = installation.type;
  report.anno = anno;

  allContatori = allContatori.filter(
    (contatore) => contatore.installation_id === installation.id
  );
  allReports = allReports.filter((r) => r.installation_id === installation.id);

  // usa i contatori attuali
  report.reportInfo = {
    contatori: buildContatori(installation, anno, allContatori, allReports),
  };

  if (
    installation.type === "CESSIONE_TOTALE" &&
    some(report.reportInfo.contatori, (c) => {
      return c.type === "ceduta";
    })
  ) {
    report.reportInfo.haContatoriDiversi = true;
  }

  const installationType = installationsByType[report.type];
  if (installationType && installationType.necessitaAlgoritmo) {
    report.reportInfo.formule = buildFormule(anno, allReports);
    report.reportInfo.costanti = buildCostanti(anno, allReports);
  }

  return report;
}

export async function createNextReport(
  installation,
  anno,
  allReports,
  allContatori,
  getAccontiReport,
  getAccontiExcelFileContentReport
) {
  allContatori = allContatori.filter(
    (contatore) => contatore.installation_id === installation.id
  );
  allReports = allReports.filter((r) => r.installation_id === installation.id);

  const report = createNextReportSyncWithoutAcconti(
    installation,
    anno,
    allReports,
    allContatori
  );

  const accontiAnnoPrecedente =
    getAccontiReport && (await getAcconti(anno, allReports, getAccontiReport));
  if (accontiAnnoPrecedente && some(accontiAnnoPrecedente, (x) => x != null)) {
    report.reportInfo.accontiAnnoPrecedente = accontiAnnoPrecedente;
  }

  if (anno - 1 >= 2022) {
    try {
      const fileExcelAnnoPrecedente =
        getAccontiExcelFileContentReport &&
        (await getAccontiExcelFileContent(
          anno,
          allReports,
          getAccontiExcelFileContentReport
        ));
      console.log("fileExcelAnnoPrecedente", fileExcelAnnoPrecedente);
      let creditoResiduoAFineAnno =
        fileExcelAnnoPrecedente &&
        fileExcelAnnoPrecedente.creditoResiduoAFineAnno;

      if (creditoResiduoAFineAnno && creditoResiduoAFineAnno.formula) {
        creditoResiduoAFineAnno = creditoResiduoAFineAnno.result;
      }
      if (typeof creditoResiduoAFineAnno !== "number") {
        creditoResiduoAFineAnno = undefined;
      }

      report.reportInfo.creditoPregressoNonUtilizzato3112 =
        creditoResiduoAFineAnno;
    } catch (err) {
      ///
    }
  }

  return report;
}

// true: se è stato usato il metodo di inserimento dati di energix
// false: se sono stati caricati i .dic e fatto in quel modo
export function isReportWithInputData(report) {
  return (
    report.reportInfo &&
    report.reportInfo.contatori &&
    report.reportInfo.contatori.length > 0
  );
}

export function isReportWithInputDataQuadri(report) {
  return report.reportInfo && report.reportInfo.datiQuadri ? true : false;
}

// se ci sono caricati alcuni file (tipo .dic, ecc)
export function isReportWithFiles(report) {
  const filesList = [
    "fileDicId",
    "fileDichiarazionePDFId",
    "fileC01Id",
    "fileC01SignedId",
    "fileXmlId",
    "fileXmlSignedId",
    "fileConfermaPDFId",
    "fileConfermaSignedId",
    "fileRiepilogoAcciseXlsId",
  ];
  return !!some(filesList, (file) => {
    return report[file];
  });
}

export function reportShouldUseXmlPerAnno(anno) {
  // ********
  // ********

  // dal 2019 in poi va usato l'xml invece del dic/c01
  // per ora fare che però su energix si può solo dal 2020 ---> ora abbiamo abilitato anche per il 2019!!!!
  // ATTENZIONE: il 2019 è un anno di cambio, quindi forse andrà chiesto qualche metodo vuole usare?
  // oppure usare un minimo report.id, che significa che se il report viene creato dopo quella data va generato xml
  const MIN_ANNO = 2019;

  // ********
  // ********

  const usaXml = anno >= MIN_ANNO + 1;
  return usaXml;
}

export function reportShouldUseXml(report) {
  return reportShouldUseXmlPerAnno(report.anno);
}

export function reportCanUseDicPerAnno(anno) {
  // ********
  // ********

  // dal 2019 in poi va usato l'xml invece del dic/c01
  // il 2019 è un anno di cambio, si possono usare tutti e due
  const MAX_ANNO = 2019;

  // ********
  // ********

  const canUsaDic = anno <= MAX_ANNO + 1;
  return canUsaDic;
}

export function reportCanUseDic(report) {
  return reportCanUseDicPerAnno(report.anno);
}
