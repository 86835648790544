import { Container, Grid } from "@mui/material";
import HeaderPaginaClienteFinale from "../../../imgs/HeaderPaginaClienteFinale.png";
import AnagraficaImpiantoBox from "src/components/features/pagina-cliente-finale/AnagraficaImpiantoBox";
import DownloadDichiarazioniBox from "src/components/features/pagina-cliente-finale/DownloadDichiarazioniBox";
import { Impianto } from "energix-types/src/Impianto";
import ServiziInScadenzaCard from "src/components/scadenze/ServiziInScadenzaCard";
import NumeroScadenzeCerchio from "src/components/scadenze/counter-card/NumeroScadenzeCerchio";
import { useFindScadenze } from "src/components/scadenze/useFindScadenze";
import { Contatore } from "energix-types/src/Contatore";
import Header from "src/components/features/pagina-cliente-finale/common/Header";
import { Dichiarazione } from "energix-types/src/Dichiarazione";
import ConsumiByQuadroCard from "src/components/consumo-by-quadro/ConsumiByQuadroCard";
import { Azienda } from "energix-types/src/Azienda";
import FirmaDichiarazione from "src/components/features/pagina-cliente-finale/FirmaDichiarazione";
import InserisciLettureDichiarazione, {
  SaveDichiarazioneFunction,
} from "../features/pagina-cliente-finale/InserisciLettureDichiarazione";

export type ClienteFinaleProps = {
  impianto: Impianto;
  contatori: Contatore[];
  azienda: Azienda;
  dichiarazioni: Dichiarazione[];
  uploadSignedXml: (idDichiarazione: number, file: File) => Promise<boolean>;
  saveDichiarazione: SaveDichiarazioneFunction;
  token: string;
};

export default function ClienteFinale({
  impianto,
  contatori,
  azienda,
  dichiarazioni,
  uploadSignedXml,
  saveDichiarazione,
  token,
}: ClienteFinaleProps) {
  const scadenze = useFindScadenze(contatori, [impianto], [azienda]);

  const sortedDichiarazioni = dichiarazioni.sort(
    ({ anno: annoA }, { anno: annoB }) => annoA - annoB
  );

  const ultimaDichiarazione =
    sortedDichiarazioni.length > 0
      ? sortedDichiarazioni[sortedDichiarazioni.length - 1]
      : undefined;

  const dichiarazioneAnnoPrecedente =
    sortedDichiarazioni.length > 1
      ? sortedDichiarazioni[sortedDichiarazioni.length - 2]
      : undefined;

  return (
    <Container sx={{ py: 4 }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            src={HeaderPaginaClienteFinale}
            style={{ maxHeight: 200 }}
            alt="Logo pagina cliente finale"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <AnagraficaImpiantoBox impianto={impianto} />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DownloadDichiarazioniBox
            token={token}
            dichiarazioni={dichiarazioni}
            impianto={impianto}
          />
        </Grid>

        {ultimaDichiarazione && (
          <Grid item sm={12}>
            <InserisciLettureDichiarazione
              dichiarazione={ultimaDichiarazione}
              impianto={impianto}
              saveDichiarazione={saveDichiarazione}
            />
          </Grid>
        )}

        {ultimaDichiarazione && (
          <Grid item sm={12}>
            <FirmaDichiarazione
              dichiarazione={ultimaDichiarazione}
              impianto={impianto}
              uploadSignedXml={uploadSignedXml}
              token={token}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <ServiziInScadenzaCard
            scadenze={scadenze}
            header={<Header title="Prossime Scadenze" />}
            Component={NumeroScadenzeCerchio}
          />
        </Grid>

        {ultimaDichiarazione && (
          <Grid item xs={12}>
            <ConsumiByQuadroCard
              dichiarazione={ultimaDichiarazione}
              impianto={impianto}
              azienda={azienda}
              dichiarazioneAnnoPrecedente={dichiarazioneAnnoPrecedente}
              header={
                <Header
                  title={`Riepilogo dichiarazione anno ${
                    ultimaDichiarazione.anno - 1
                  }`}
                />
              }
            />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
